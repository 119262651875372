#acceptor-list-item {
  ion-avatar {
    width: 100% !important;
    height: 100% !important;
    max-width: 70px !important;
    max-height: 70px !important;
    margin: 7px;
  }
}

.dep-logo {
  border-radius: 10px;
  width: 58px !important;
  height: 58px !important;
  float: right;
  clear: both;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#acceptorKalaSearchModal {
  .modal-wrapper {
    height: 100vh;
  }
}
