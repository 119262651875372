#acceptor-card-component {
  ion-item::after(native) {
    padding-left: unset;
    border-radius: 15px !important;
  }
  .overal-score {
    direction: ltr;
    text-align: left;
    width: 28px;
    margin: 0;
    align-self: center;
  }
}
