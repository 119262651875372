.titr {
  --background: #000;
  color: #fff;
  font-size: 1.4em;
  text-align: center;
  border: none !important;
  border-radius: 0;
}

.purchase-score {
  background: #fff url('./images/purchase-score-bg.png') no-repeat center top / contain;
  border: none !important;
  height: 74vh;
  ion-col {
    padding: 0px;
    text-align: center
  }
  .coin-row {
    height: 100%;
    img {
      cursor: pointer;
      max-width: 65%;
    }
  }
}
