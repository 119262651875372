#home-page {
  .btn-group-fab {
    bottom: 60px;
  }
  ion-card {
    margin: 0;
    border-radius: 0;
  }
}

.ios ion-tab-bar {
  margin-bottom: 10px;
}

.persepolis-theme {
  #totals {
    color: var(--ion-color-persepolis);
    .total {
      color: var(--ion-color-persepolis-contrast);
      background-color: var(--ion-color-persepolis);
    }
    .score {
      color: var(--ion-color-persepolis-contrast);
      background-color: var(--ion-color-persepolis-tint);
    }
  }
  #lottery {
    background-color: #da8185;
    color: #fff;
    border: none;
    img {
      width: 80px;
      height: 80px;
    }
  }
  #rate-purchase {
    background-color: #404040;
    img {
      width: 80px;
      height: 80px;
    }
  }
  ion-card {
    border: 1px solid red;
  }
}

.ayria-theme {
  #home-page {
    ion-card {
      border: none;
    }
  }
  #totals {
    color: var(--ion-color-ayria);
    .total {
      color: var(--ion-color-ayria-contrast);
      background-color: var(--ion-color-ayria);
    }
    .score {
      color: var(--ion-color-ayria-contrast);
      background-color: var(--ion-color-ayria-tint);
    }
  }
  #rate-purchase {
    background-color: #ffd200;
  }
}

.karafarin-theme {
  #home-page {
    ion-card {
      border: none;
    }
  }
}

.dark-theme {
  #home-page {
    ion-card {
      border: none;
    }
  }
  #rate-purchase {
    background-color: black;
  }
}

#totals {
  .total {
    padding-right: 60px;
    padding-left: 60px;
  }
  .score {
    padding-right: 20px;
    padding-left: 20px;
    margin: 0;
  }
}

#rate-purchase {
  color: white;
  border: none;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-center-vertical {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-space-between-vertical {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.height-100-58 {
  height: calc(100% - 58px);
}

#unrate-card {
  border-top: none;
  border-bottom: none;
}

#banner {
  border-bottom: none;
}
