.originalClassImageGallery {
  margin: 0 10px;
  //.image-gallery-slide{
  //div{
  //  border-radius: 20px;
  //  overflow: hidden;
  //}
  //}
  .image-gallery-image {
    height: 140px;
    object-fit: cover !important;
    border-radius: 20px;
    //border-radius: 10px;
  }
}
#acceptor-list-item {
  ion-avatar {
    width: 100% !important;
    height: 100% !important;
    max-width: 70px !important;
    max-height: 70px !important;
    margin: 7px;
  }
}
.acceptor-list {
  display: flex;
  flex-direction: row;
  //-webkit-flex-direction: row !important;
  overflow: auto;
}

.dep-logo {
  width: 50px !important;
  height: 50px !important;
  //float: right;
  clear: both;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#acceptorKalaSearchModal {
  .modal-wrapper {
    height: 100vh;
  }
}

.swiper-wrapper {
  transform: unset !important;
  width: 100% !important;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.swiper-slide {
  margin-left: unset !important;
}
