/* ------------------------------------------------
  Project:   Softino - Software Landing Page HTML5 Template
  Author:    ThemeHt
------------------------------------------------ */
/* ------------------------
    Table of Contents

  1. Accordion
  2. Social
  3. Team
  4. Tab
  5. Counter
  6. Blog - Post
  7. Testimonials
  8. Featured Box
  9. Button
  10. Price Table
  11. Section Title
  12. Progress Bar


/* ------------------------
    Accordion
------------------------*/
.accordion .card {
  border: none;
  border-radius: 0;
  margin-bottom: 25px;
  overflow: inherit;
}
.accordion .card:last-child {
  margin-bottom: 0;
}
.accordion .card-header {
  background: none;
  border: none;
  position: relative;
  padding: 0;
}
.accordion .card-header a {
  padding: 20px 30px 20px 20px;
  border-radius: 7px;
  background: #ffffff;
  font-weight: 600;
  font-size: 15px;
  color: #1c1d3e;
  text-transform: capitalize;
  display: inline-block;
  width: 100%;
}
.accordion .card.active a,
.accordion .card a:hover {
  background: #00b9c8;
  color: #ffffff;
}
.accordion .card-body {
  padding: 30px;
  text-align: justify;
}
.accordion .card-body .span {
  text-align: justify;
}
.accordion {
  margin-top: -25px;
  padding-top: 24px;
}
.accordion .card-header a span:after,
.accordion .card-header a span:before,
.accordion .card-header a span {
  content: '';
  position: absolute;
  left: 20px;
  top: 50%;
}
.accordion .card-header a span:before,
.accordion .card-header a span:after {
  width: 20px;
  height: 2px;
  background: #00b9c8;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.accordion .card-header a span:before {
  -webkit-transform: translate(-50%) rotate(90deg);
  transform: translate(-50%) rotate(90deg);
}
.accordion .card-header a span {
  left: inherit;
  left: 30px;
}
.accordion .card-header a[aria-expanded='true'] span:before {
  -webkit-transform: translate(-50%) rotate(0deg);
  transform: translate(-50%) rotate(0deg);
}
.accordion .card.active a span:after,
.accordion .card a:hover span:after,
.accordion .card.active a span:before,
.accordion .card a:hover span:before {
  background: #ffffff;
}

.dark-bg .accordion .card {
  background: rgba(0, 0, 0, 0.2);
}
.dark-bg .accordion .card-header a {
  color: rgba(255, 255, 255, 0.5);
}
.dark-bg .accordion .card.active a,
.dark-bg .accordion .card a:hover {
  color: #00b9c8;
}

/* ------------------------
    Social Icons
------------------------*/
.social-icons ul {
  padding: 0;
  margin: 0;
  line-height: 0;
}
.text-center.social-icons ul {
  display: inline-block;
}
.social-icons li {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0 8px 0 0;
}
.social-icons li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  margin: 0;
  border-radius: 4px;
  border: 0;
  background: transparent;
  color: #111121;
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.text-white .social-icons li a {
  color: #ffffff;
}
.social-icons.fullwidth li a {
  width: auto;
  height: auto;
  line-height: 20px;
  padding: 10px 15px;
}
.social-icons li:hover a {
  transform: rotateY(360deg);
}

/*** Colored ***/
.social-icons.social-colored a,
.social-icons.social-hover a:hover {
  color: #fff;
  border: 0;
}

.social-icons.social-colored .social-rss a,
.social-icons.social-hover .social-rss a:hover {
  background-color: #faa33d;
}

.social-icons.social-colored .social-facebook a,
.social-icons.social-hover .social-facebook a:hover {
  background-color: #5d82d1;
}

.social-icons.social-colored .social-twitter a,
.social-icons.social-hover .social-twitter a:hover {
  background-color: #40bff5;
}

.social-icons.social-colored .social-vimeo a,
.social-icons.social-hover .social-vimeo a:hover {
  background-color: #35c6ea;
}

.social-icons.social-colored .social-myspace a,
.social-icons.social-hover .social-myspace a:hover {
  background-color: #008dde;
}

.social-icons.social-colored .social-youtube a,
.social-icons.social-hover .social-youtube a:hover {
  background-color: #ef4e41;
}

.social-icons.social-colored .social-instagram a,
.social-icons.social-hover .social-instagram a:hover {
  background-color: #548bb6;
}

.social-icons.social-colored .social-gplus a,
.social-icons.social-hover .social-gplus a:hover {
  background-color: #db5442;
}

.social-icons.social-colored .social-stumbleupon a,
.social-icons.social-hover .social-stumbleupon a:hover {
  background-color: #ff5c30;
}

.social-icons.social-colored .social-lastfm a,
.social-icons.social-hover .social-lastfm a:hover {
  background-color: #f34320;
}

.social-icons.social-colored .social-pinterest a,
.social-icons.social-hover .social-pinterest a:hover {
  background-color: #e13138;
}

.social-icons.social-colored .social-google a,
.social-icons.social-hover .social-google a:hover {
  background-color: #eb5e4c;
}

.social-icons.social-colored .social-evernote a,
.social-icons.social-hover .social-evernote a:hover {
  background-color: #9acf4f;
}

.social-icons.social-colored .social-dribbble a,
.social-icons.social-hover .social-dribbble a:hover {
  background-color: #f7659c;
}

.social-icons.social-colored .social-skype a,
.social-icons.social-hover .social-skype a:hover {
  background-color: #13c1f3;
}

.social-icons.social-colored .social-forrst a,
.social-icons.social-hover .social-forrst a:hover {
  background-color: #45ad76;
}

.social-icons.social-colored .social-linkedin a,
.social-icons.social-hover .social-linkedin a:hover {
  background-color: #238cc8;
}

.social-icons.social-colored .social-wordpress a,
.social-icons.social-hover .social-wordpress a:hover {
  background-color: #2592c3;
}

.social-icons.social-colored .social-grooveshark a,
.social-icons.social-hover .social-grooveshark a:hover {
  background-color: #ffb21d;
}

.social-icons.social-colored .social-delicious a,
.social-icons.social-hover .social-delicious a:hover {
  background-color: #377bda;
}

.social-icons.social-colored .social-behance a,
.social-icons.social-hover .social-behance a:hover {
  background-color: #1879fd;
}

.social-icons.social-colored .social-dropbox a,
.social-icons.social-hover .social-dropbox a:hover {
  background-color: #17a3eb;
}

.social-icons.social-colored .social-soundcloud a,
.social-icons.social-hover .social-soundcloud a:hover {
  background-color: #ff7e30;
}

.social-icons.social-colored .social-deviantart a,
.social-icons.social-hover .social-deviantart a:hover {
  background-color: #6a8a7b;
}

.social-icons.social-colored .social-yahoo a,
.social-icons.social-hover .social-yahoo a:hover {
  background-color: #ab47ac;
}

.social-icons.social-colored .social-flickr a,
.social-icons.social-hover .social-flickr a:hover {
  background-color: #ff48a3;
}

.social-icons.social-colored .social-digg a,
.social-icons.social-hover .social-digg a:hover {
  background-color: #75788d;
}

.social-icons.social-colored .social-blogger a,
.social-icons.social-hover .social-blogger a:hover {
  background-color: #ff9233;
}

.social-icons.social-colored .social-tumblr a,
.social-icons.social-hover .social-tumblr a:hover {
  background-color: #426d9b;
}

.social-icons.social-colored .social-quora a,
.social-icons.social-hover .social-quora a:hover {
  background-color: #ea3d23;
}

.social-icons.social-colored .social-github a,
.social-icons.social-hover .social-github a:hover {
  background-color: #3f91cb;
}

.social-icons.social-colored .social-amazon a,
.social-icons.social-hover .social-amazon a:hover {
  background-color: #ff8e2e;
}

.social-icons.social-colored .social-xing a,
.social-icons.social-hover .social-xing a:hover {
  background-color: #1a8e8c;
}

.social-icons.social-colored .social-wikipedia a,
.social-icons.social-hover .social-wikipedia a:hover {
  background-color: #b3b5b8;
}

.social-icons.social-colored .social-android a,
.social-icons.social-hover .social-android a:hover {
  background-color: #a4c639;
}

.social-icons.social-colored .social-apple a,
.social-icons.social-hover .social-apple a:hover {
  background-color: #999999;
}

/*** Border ***/
.social-icons.social-border li a {
  border: 1px solid #efefef;
  background: transparent;
}

/*** Circle ***/
.social-icons.circle li a {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* ------------------------
    Team
------------------------*/
.team-member {
  position: relative;
  text-align: center;
}
.owl-carousel .team-member {
  margin: 30px 15px;
}
.team-images {
  overflow: hidden;
  position: relative;
  padding-bottom: 50px;
}
.team-member .team-description {
  background: #ffffff;
  padding: 20px;
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 9;
}
.team-member.style-1 .team-description {
  background: #ffffff;
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
}
.team-member .team-description span {
  color: #1c1d3e;
  font-weight: 400;
}
.team-member .team-description h5 {
  margin: 0;
  text-transform: capitalize;
  font-weight: 500;
  color: #00b9c8;
}
.team-social-icon {
  margin-top: 15px;
}
.team-member.style-1 .team-social-icon {
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 500;
}
.team-member:hover .team-social-icon {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.team-member .team-social-icon ul {
  display: inline-block;
}
.team-member .team-social-icon ul li {
  list-style-type: none;
  position: relative;
}
.team-member.style-1 .team-social-icon ul li {
  margin-bottom: 15px;
  margin-right: 0;
  display: block;
}
.team-member img {
  width: 100%;
}
.team-member p {
  margin: 15px 0;
}

.team-link {
  display: block;
  font-weight: 600;
  font-size: 30px;
  border-radius: 7px;
  color: #1c1d3e;
  background: #ffffff;
  height: 60px;
  width: 60px;
  line-height: 60px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  position: absolute;
  top: 2rem;
  left: 1rem;
  z-index: 500;
}
.team-link:hover {
  color: #00b9c8;
}
.team-member:hover .team-link {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.team-member.style-1:hover .team-description {
  padding: 30px 20px;
}

.team-description .team-icon li a {
  color: #1c1d3e;
}
.team-description .team-icon li a:hover {
  color: #00b9c8;
}
.team-description .team-icon li i {
  display: inline-block;
  border-radius: 7px;
  background: #ffffff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #00b9c8;
  font-size: 24px;
  vertical-align: middle;
  margin-left: 10px;
}

.team-member.style-2 .team-description {
  border-radius: 7px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
  width: 85%;
  padding: 30px 20px;
}
.team-member.style-2 .team-social-icon {
  position: inherit;
  transform: inherit;
  opacity: 1;
  left: inherit;
  top: inherit;
  visibility: visible;
}
.team-member.style-2 .team-social-icon ul li {
  display: inline-block;
}

.team-member.style-3 {
  background: #fff;
  border-radius: 7px;
  padding: 30px;
}
.team-member.style-3 .team-images {
  /*border-radius: 50%;*/
  height: 200px;
  overflow: hidden;
  position: relative;
  width: 200px;
  margin: 0 auto;
}
/*.team-member.style-3 img{border-radius: 50%;}*/
.team-member.style-3 .team-link {
  right: 50%;
  margin-right: -30px;
  top: 50%;
  margin-top: -30px;
  border-radius: 50%;
}
.team-member.style-3:before,
.team-member.style-3:after {
  content: '';
  background: #00b9c8;
  border-radius: 7px;
  width: 0;
  height: 5px;
  position: absolute;
}
.team-member.style-3:before {
  top: 0;
  left: 0;
}
.team-member.style-3:after {
  bottom: 0;
  right: 0;
}
.team-member.style-3:hover:before,
.team-member.style-3:hover:after {
  width: 30%;
}
.team-member.style-3 .team-description {
  padding-left: 0;
  padding-right: 0;
}

.team-member.style-4 .team-images {
  overflow: hidden;
  position: relative;
  width: 70%;
  border-bottom-left-radius: 70%;
}
.team-member.style-4 .team-description {
  position: absolute;
  left: 0;
  text-align: left;
  top: 50%;
  width: 80%;
  border-radius: 7px;
  transform: translateY(-50%);
}
.team-member.style-4:hover .team-description {
  transform: inherit;
}

/* ------------------------
    Tab
------------------------*/
.tab .nav-tabs {
  border: none;
  display: inline-block;
}
.tab .nav-tabs .nav-link {
  background: #ffffff;
  border-radius: 5px;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #1c1d3e;
  float: left;
  position: relative;
  display: inline-block;
  border: none;
  padding: 15px 30px;
}
.tab .nav-tabs .nav-link:last-child {
  margin-right: 0;
}
.tab .nav-tabs .nav-link.active,
.tab .nav-tabs .nav-link:hover,
.tab .nav-tabs.active > a:focus,
.tab .nav-tabs > a:hover {
  background: #00b9c8;
  color: #ffffff;
}
.tab .tab-content {
  padding: 30px;
}
.tab .nav-tabs {
  display: table;
  width: 100%;
  text-align: center;
}

.tab.style-2 {
  padding-top: 30px;
}
.tab.style-2 .nav-tabs .nav-link {
  background: none !important;
  padding: 0;
  display: table-cell;
  width: auto;
  float: none;
}
.tab.style-2 .nav-tabs .nav-link i::after {
  background: #005bea;
  border-radius: 100%;
  box-sizing: border-box;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s ease-in-out 0s;
  width: 100%;
  z-index: -1;
  transform: scale(0);
}
.tab .nav-tabs .nav-link.active i::after,
.tab .nav-tabs.active > a:focus i::after {
  background: #00b9c8;
}
.tab.style-2 .nav-tabs .nav-link i {
  color: #1c1d3e;
  font-size: 50px;
  line-height: 90px;
  margin: 0 auto;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
  z-index: 1;
  display: block;
  height: 90px;
  width: 90px;
}
.tab.style-2 .nav-tabs .nav-link h5 {
  margin: 0 0 1em;
  transition: all 0.2s ease-in-out 0s;
}
.tab.style-2 .nav-tabs .nav-link:hover h5,
.tab.style-2 .nav-tabs .nav-link.active h5,
.tab.style-2 .nav-tabs.active > a:focus h5 {
  color: #00b9c8;
  margin: 0.5em 0;
}
.tab.style-2 .nav-tabs .nav-link:hover i,
.tab.style-2 .nav-tabs .nav-link.active i,
.tab.style-2 .nav-tabs.active > a:focus i {
  color: #ffffff;
}
.tab.style-2 .nav-tabs .nav-link:hover i::after,
.tab.style-2 .nav-tabs .nav-link.active i::after,
.tab.style-2 .nav-tabs.active > a:focus i::after {
  transform: scale(1);
}
.tab.style-2 .tab-content {
  padding-top: 50px;
}

/* ------------------------
    Counter
------------------------*/
.counter {
  position: relative;
  text-align: center;
  background: #ffffff;
  border-radius: 7px;
  overflow: hidden;
  padding: 30px;
}
.counter span {
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  color: #005bea;
  display: inline-block;
  margin: 30px 0 10px;
}
.counter span + span {
  font-weight: normal;
  font-size: 30px;
  color: #1c1d3e;
}
.counter h5 {
  display: block;
  font-weight: normal;
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  margin: 0;
}

.counter.style-2 {
  background: none;
  box-shadow: none;
  padding: 0;
  text-align: right;
}
.counter.style-2 span {
  margin-top: 0;
}
.text-center .counter.style-2 {
  text-align: center;
}

.counter.style-3 {
  background: none;
  box-shadow: none;
  padding: 0;
  text-align: right;
  display: flex;
  align-items: center;
}
.counter.style-3 .counter-icon {
  margin-left: 15px;
}
.counter.style-3 span {
  margin-top: 0;
}

.counter.style-4 {
  display: flex;
  align-items: center;
  padding: 0;
  overflow: inherit;
  margin: 0 auto;
  width: 10em;
  height: 15.32em;
  border-radius: 1em/0.5em;
  transition: opacity 0.5s;
}
.counter.style-4:before,
.counter.style-4:after {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: '';
}
.counter.style-4:before {
  background: #fafaff;
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.counter.style-4:after {
  background: #ffffff;
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
.counter.style-4 .counter-desc {
  position: relative;
  z-index: 9;
  width: 100%;
}

/* ------------------------
    Blog - Post
------------------------*/
.post {
  position: relative;
  border-radius: 7px;
}
.owl-carousel .post {
  margin: 0 15px;
}
.post .post-image {
  overflow: hidden;
  position: relative;
}
.post .post-desc {
  padding: 30px;
  background: #ffffff;
}
.post .post-desc h4 {
  margin: 0;
  text-transform: capitalize;
  font-weight: bold;
}
.post .post-desc h4 a {
  color: #1c1d3e;
  display: inline-block;
}
.post .post-desc h4 a:hover {
  color: #00b9c8;
}
.post .post-desc p {
  margin: 15px 0;
  font-size: 14px;
  color: #828893;
}
.post-meta ul li {
  font-size: 14px;
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 10px;
  list-style-type: none;
  position: relative;
}
.post-meta ul li:last-child {
  margin-left: 0;
}
.post-meta ul li i {
  color: #00b9c8;
}
.post:hover {
  border-radius: 15px;
}
.post .post-image::before {
  background-color: #00b9c8;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 600ms ease 0s;
  width: 0;
  opacity: 0.7;
}
.post .post-image::after {
  background-color: #00b9c8;
  bottom: 0;
  content: '';
  display: block;
  height: 50%;
  position: absolute;
  right: 0;
  transition: all 600ms ease 0s;
  width: 0;
  opacity: 0.7;
}
.post:hover .post-image::before,
.post:hover .post-image::after {
  width: 100%;
}
.post-categories {
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
  padding: 8px 20px;
  color: #ffffff;
  border-top-left-radius: 7px;
  text-transform: uppercase;
  background: #00b9c8;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 500;
}
.post:hover .post-categories {
  background: #1c1d3e;
}
.post.style-2:hover .post-categories {
  background: #ffffff;
  color: #00b9c8;
}
.post-categories:hover {
  color: #005bea;
}
.post-title {
  position: relative;
  padding-top: 20px;
  margin-top: 20px;
}
.post-title:before,
.post-title:after {
  content: '';
  position: absolute;
  z-index: 1;
  height: 3px;
  border-radius: 2px;
  top: 0;
}
.post-title:before {
  background: #005bea;
  width: 150px;
  right: -30px;
}
.post-title:after {
  background: #00b9c8;
  width: 20px;
  right: 130px;
}
.post-author {
  position: absolute;
  left: 30px;
  bottom: -25px;
}
.post-author-img {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: top;
}
.post-author span {
  font-size: 16px;
  color: #00b9c8;
  font-weight: 500;
  padding-left: 15px;
  padding-top: 15px;
  display: inline-block;
}

.post.style-2 {
  overflow: hidden;
}
.post.style-2 .post-desc h4 a,
.post.style-2 .post-desc p,
.post.style-2 .post-meta ul li,
.post.style-2 .post-meta ul li i {
  color: #ffffff;
}
.post.style-2 .post-desc {
  background: none;
  box-shadow: none;
  bottom: -10px;
  left: 0;
  position: absolute;
  right: 0;
  opacity: 0;
  z-index: 99;
  transition: bottom 0.3s ease 0s, opacity 0.3s cubic-bezier(0.33, 0.66, 0.66, 1) 0s;
}
.post.style-2:hover .post-desc,
.post.style-2.active .post-desc {
  opacity: 1;
  bottom: 0;
}
.post.style-2:before {
  content: '';
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: transform 500ms ease 0s, box-shadow 500ms ease 0s;
  z-index: 9;
}
.post.style-2:hover:before,
.post.style-2.active:before {
  transform: translateY(0);
}
.post.style-2 .post-categories {
  position: relative;
  bottom: inherit;
  left: inherit;
  border-radius: 7px;
  margin-bottom: 15px;
}
.post.style-2 p {
  margin-bottom: 0;
}

.post.style-3 {
  box-shadow: none;
  border-radius: 0;
}
.post.style-3 .post-desc {
  background: none;
}
.post.style-3 .post-image {
  border-radius: 7px;
}
.post.style-3 .post-title {
  padding-top: 0;
  margin-top: 0;
}
.post.style-3 .post-title:before,
.post.style-3 .post-title:after {
  display: none;
}
.post.style-3 .post-meta {
  position: relative;
  padding-top: 20px;
  margin-top: 20px;
}
.post.style-3 .post-meta:before,
.post.style-3 .post-meta:after {
  content: '';
  position: absolute;
  z-index: 1;
  height: 3px;
  border-radius: 2px;
  top: 0;
}
.post.style-3 .post-meta:before {
  background: #005bea;
  width: 100px;
  right: 0;
}
.post.style-3 .post-meta:after {
  background: #00b9c8;
  width: 20px;
  right: 110px;
}

.post.style-4 {
  box-shadow: none;
  overflow: inherit;
}
.post.style-4 .post-desc {
  border-radius: 7px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
  width: 85%;
  padding: 30px 20px;
  position: relative;
}
.post.style-4 h4 {
  font-size: 22px;
}

.video-post {
  padding-top: 56.9444%;
  position: relative;
  width: 100%;
}
.video-post iframe,
.video-post object,
.video-post embed {
  pointer-events: auto;
  border: medium none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.post-queto .item {
  background: #ff156a;
  padding: 30px 30px 30px 100px;
  font-size: 16px;
  font-style: italic;
  line-height: 30px;
}
.post-queto .item span {
  color: rgba(0, 0, 0, 0.1);
  font-size: 120px;
  left: 30px;
  line-height: 130px;
  position: absolute;
  top: 50%;
}

.media-holder .media {
  position: relative;
}
.media.inner {
  background: #fafaff;
  padding: 30px;
  margin: 50px 0 50px 50px;
  border-radius: 7px;
}
.media-holder .media img {
  border-radius: 50%;
  width: 100px;
  -webkit-box-shadow: 0 20px 40px 0 rgba(28, 30, 35, 0.2);
  -moz-box-shadow: 0 20px 40px 0 rgba(28, 30, 35, 0.2);
  box-shadow: 0 20px 40px 0 rgba(28, 30, 35, 0.2);
}
.media-body h6 {
  color: #1c1d3e;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
}

.comment-date {
  display: block;
  color: #005bea;
  margin-top: 10px;
  text-transform: uppercase;
}
.comment-reply {
  position: absolute;
  left: 0;
  top: 0;
}
.comment-reply a {
  background: #ffffff;
  padding: 10px 20px;
  border-radius: 30px;
  color: #1c1d3e;
  font-weight: 500;
  display: inline-block;
}
.comment-reply a:hover {
  color: #00b9c8;
}
.media-body p {
  margin: 15px 0 0;
}
.media.inner .comment-reply {
  top: 30px;
  left: 30px;
}

.pagination {
  display: inline-block;
}
.pagination > li {
  margin: 0 6px;
  display: inline-block;
}
.pagination > li > a,
.pagination > li > span {
  width: 50px;
  height: 50px;
  line-height: 50px;
  padding: 0;
  text-align: center;
  color: #1c1d3e;
  font-weight: 500;
  border-radius: 50% !important;
  border-color: #efefef;
}
.pagination > li.active .page-link,
.page-link:hover,
.page-link:focus {
  box-shadow: none;
  background: #00b9c8;
  color: #ffffff;
  border-color: #00b9c8;
}

/*Widget-Title*/
.widget-title {
  background: #ffffff;
  border-radius: 7px;
  margin-top: 50px;
  padding: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #1c1d3e;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

/*Widget-Search*/
.widget-searchbox {
  position: relative;
  width: 100%;
}
.form-inline .form-control {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.widget-searchbox .search-btn {
  background: none;
  border: none;
  color: #00b9c8;
  font-size: 14px;
  outline: medium none;
  overflow: hidden;
  position: absolute;
  left: 20px;
  top: 50%;
  cursor: pointer;
}

/*Recent Post*/
.widget .recent-post-thumb {
  display: table-cell;
  vertical-align: middle;
  height: auto;
  width: 75px;
  border-radius: 7px;
  overflow: hidden;
}
.widget .recent-post-desc {
  display: table-cell;
  vertical-align: middle;
  padding-right: 15px;
}
.widget .recent-post-desc a {
  display: block;
  margin-top: 10px;
  color: #1c1d3e;
  font-weight: 500;
}
.widget .recent-post-desc a:hover {
  color: #00b9c8;
}

/*widget-Categories*/
.widget .widget-categories li {
  display: block;
  padding: 15px 0;
  border-bottom: 1px dashed #ddd;
  position: relative;
}
.widget .widget-categories li:first-child {
  padding-top: 0;
}
.widget .widget-categories li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.widget .widget-categories li a {
  text-transform: capitalize;
  color: #1c1d3e;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}
.widget .widget-categories li a:hover {
  color: #00b9c8;
  padding-left: 5px;
}
.widget-categories li i {
  position: absolute;
  right: 0;
  color: #005bea;
}

/*widget-tags*/
.widget .widget-tags li {
  display: inline-block;
  margin: 5px 5px 15px 0;
}
.widget .widget-tags li a {
  background: #fafaff;
  border-radius: 5px;
  color: #1c1d3e;
  padding: 10px 18px;
  display: inline-block;
  text-align: center;
  font-weight: normal;
}
.widget .widget-tags li a:hover {
  background: #00b9c8;
  color: #ffffff;
}

/*post-tags*/
.post-tags li {
  display: inline-block;
  margin: 5px 5px 15px 0;
}
.post-tags li a {
  background: #fafaff;
  border-radius: 5px;
  color: #1c1d3e;
  padding: 10px 18px;
  display: inline-block;
  text-align: center;
  font-weight: normal;
}
.post-tags li a:hover {
  background: #00b9c8;
  color: #ffffff;
}

.main-post .recent-post-thumb {
  width: 120px;
}
.main-post .recent-post-thumb img {
  width: 100%;
}

/* ------------------------
    Testimonials
------------------------*/
.testimonial {
  position: relative;
  padding: 30px 30px 30px 0;
}
.testimonial.style-1 {
  padding: 30px 0 30px 30px;
}
.testimonial.style-1:before {
  content: '';
  background: #00b9c8;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  width: 20%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.testimonial-content {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 0;
}
.testimonial.style-1 .testimonial-content {
  padding: 30px;
  margin-right: 50px;
  border-radius: 7px;
  background: #ffffff;
  overflow: hidden;
}
.testimonial-content p {
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 0;
  color: #1c1d3e;
  position: relative;
}
.testimonial.style-1 .testimonial-content p {
  position: relative;
  z-index: 9;
}
.testimonial.style-1 .testimonial-caption {
  z-index: 9;
  position: relative;
}
.testimonial .testimonial-caption label {
  margin-bottom: 0;
  font-weight: 500;
  color: #1c1d3e;
  display: block;
}
.testimonial .testimonial-caption h5 {
  color: #00b9c8;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 10px;
}
.testimonial .testimonial-quote {
  color: #ffe2e1;
  z-index: -1;
  font-size: 200px;
  line-height: 200px;
  transform: rotate(-90deg);
  position: absolute;
  top: 0;
  right: 0;
}
.testimonial.style-1 .testimonial-quote {
  z-index: 9;
}
.grey-bg .testimonial-content p {
  color: #1c1d3e;
}
.testimonial.style-1 .testimonial-content::before {
  background: #fafaff;
  content: '';
  height: 300px;
  left: -25%;
  position: absolute;
  top: 10%;
  transform: rotate(-10deg);
  width: 150%;
}

.testimonial-caption {
  position: relative;
  padding-top: 20px;
  margin-top: 20px;
  display: inline-block;
}
.testimonial-caption:before,
.testimonial-caption:after {
  content: '';
  position: absolute;
  z-index: 1;
  height: 3px;
  border-radius: 2px;
  top: 0;
}
.testimonial-caption:before {
  background: #005bea;
  width: 100px;
  left: 0;
}
.testimonial-caption:after {
  background: #00b9c8;
  width: 20px;
  left: 110px;
}

.testimonial-carousel .controls {
  padding: 0;
  margin: 0;
  white-space: nowrap;
  text-align: center;
  position: relative;
}
.testimonial-carousel .controls li {
  position: relative;
  margin: 0 2%;
  border-radius: 50%;
  width: 46%;
  box-shadow: 0 30px 70px rgba(0, 0, 0, 0.2);
}
.testimonial-carousel .controls li a {
  position: relative;
  z-index: 9;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.testimonial-carousel .controls li:last-child {
  float: none;
  margin: 0 auto;
}
.testimonial-carousel .controls li img {
  width: 100%;
  height: 100%;
}

.testimonial-carousel .controls li.active:after,
.testimonial-carousel .controls li.active:before,
.testimonial.style-2 .testimonial-img:before,
.testimonial.style-2 .testimonial-img:after {
  content: '';
  border: 10px solid #00b9c8;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  left: 0%;
  top: 0%;
  opacity: 1;
  -webkit-animation: 2s videomodule-anim linear infinite;
  animation: 2s videomodule-anim linear infinite;
}
.testimonial-carousel .controls li.active:before {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.testimonial.style-2 {
  text-align: center;
  padding: 30px 30px 70px;
}
.testimonial.style-2:before {
  display: none;
}
.testimonial-img {
  border-radius: 50%;
  position: relative;
  display: inline-block;
}
.testimonial-img img {
  border-radius: 50%;
  position: relative;
  z-index: 9;
  width: 100%;
}
.testimonial.style-2 .testimonial-img {
  width: 35%;
}
.testimonial.style-2 .testimonial-content {
  background: #ffffff;
  padding: 30px;
  border-radius: 7px;
  margin-top: 30px;
}
.testimonial.style-2 .testimonial-content:before {
  content: '';
  background: #ffffff;
  width: 96%;
  height: 100px;
  position: absolute;
  left: 2%;
  bottom: -20px;
  z-index: -1;
}
.testimonial.style-2 .testimonial-content:after {
  content: '';
  background: #ffffff;
  width: 92%;
  height: 100px;
  position: absolute;
  left: 4%;
  bottom: -40px;
  z-index: -9;
}

.testimonial-2 .carousel-inner {
  z-index: 9;
}
.testimonial-carousel.testimonial-2 .controls {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
}
.testimonial-carousel.testimonial-2 .controls li {
  animation-name: blinkblink;
  animation-duration: 5000ms;
  transform-origin: 70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  z-index: 99;
  opacity: 0.6;
  width: 130px;
  height: 130px;
  margin: inherit;
  float: none;
}
.testimonial-carousel.testimonial-2 .controls li.active {
  opacity: 1;
  animation: inherit;
}
.testimonial-2 .controls li:nth-child(1) {
  left: 0;
  bottom: 10%;
}
.testimonial-2 .controls li:nth-child(2) {
  left: 5%;
  bottom: 55%;
}
.testimonial-2 .controls li:nth-child(3) {
  right: 5%;
  bottom: 55%;
}
.testimonial-2 .controls li:nth-child(4) {
  right: 0;
  bottom: 10%;
}

.testimonial.style-3,
.testimonial.style-5 {
  display: flex;
  align-items: center;
}
.testimonial.style-3 .testimonial-img,
.testimonial.style-5 .testimonial-img {
  border-radius: 20% 30% 80% 0;
  display: inline-block;
  overflow: hidden;
  position: relative;
  margin-left: 30px;
  width: 100%;
}
.testimonial.style-3 .testimonial-img img,
.testimonial.style-5 .testimonial-img img {
  border-radius: 0;
}
.testimonial.style-3 .testimonial-quote {
  color: #ffe2e1;
  left: 0;
  right: inherit;
  transform: rotate(-180deg);
}
.testimonial-carousel.testimonial-3 .controls {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
}
.testimonial-carousel.testimonial-3 .controls li {
  position: absolute;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  z-index: 99;
  opacity: 0.6;
  width: 130px;
  height: 130px;
  margin: inherit;
  float: none;
  width: 80px;
  height: 80px;
  animation: inherit;
}
.testimonial-3 .controls li:nth-child(1) {
  right: 0;
  bottom: 0%;
}
.testimonial-3 .controls li:nth-child(2) {
  right: 3%;
  bottom: 30%;
}
.testimonial-3 .controls li:nth-child(3) {
  right: 0;
  bottom: 60%;
}
.testimonial-carousel.testimonial-3 .controls li.active {
  opacity: 1;
}

.testimonial.style-4 {
  background: #00b9c8;
  padding: 30px;
  border-radius: 7px;
}
.testimonial.style-4 .testimonial-img {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-right: 15px;
}
.testimonial.style-4 .testimonial-content p {
  font-size: 15px;
  margin-bottom: 30px;
}
.testimonial.style-4 .testimonial-caption {
  display: inline-block;
  vertical-align: middle;
}
.testimonial.style-4:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #b3dbb6;
  border-radius: 30px;
  transform: rotate(-7deg);
  z-index: -2;
}

.testimonial.style-5 {
  margin: 0 30px;
}
.testimonial.style-5 .testimonial-img {
  margin-right: 0;
}
.testimonial.style-5 .testimonial-content {
  background: #fff;
  margin-right: -150px;
  border-radius: 7px;
  padding: 50px 200px 50px 50px;
}
.testimonial.style-5 .testimonial-quote {
  position: inherit;
  top: inherit;
  right: inherit;
  z-index: inherit;
  transform: inherit;
  line-height: 150px;
}

.anti-01-holder {
  width: 110px;
  height: 110px;
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-animation: rotation 6s infinite linear;
}
.anti-01 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #00b9c8;
  position: absolute;
  left: 0px;
  top: 0px;
}
.anti-02-holder {
  width: 70px;
  height: 70px;
  opacity: 0.1;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -35px;
  -webkit-animation: rotation 6s infinite linear;
  animation-delay: 0s;
  -webkit-animation-delay: 3s;
}
.anti-02 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #005bea;
  position: absolute;
  left: 0px;
  top: 0px;
}
.anti-03-holder {
  width: 75px;
  height: 75px;
  opacity: 0.1;
  position: absolute;
  right: 30px;
  top: 55%;
  -webkit-animation: anti-rotation 8s infinite linear;
  animation-delay: 0s;
  -webkit-animation-delay: 1s;
}
.anti-03 {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background: linear-gradient(to right, #005bea, #00b9c8);
  position: absolute;
  left: 0px;
  top: 0px;
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
  padding: 20px 20px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  z-index: 9;
  text-align: center;
}
.owl-carousel .featured-item {
  margin: 20px;
}
.featured-item .featured-icon {
  display: inline-block;
  position: relative;
}
.featured-desc p {
  margin-bottom: 0;
}
.featured-title h5 {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: bold;
}
.dark-bg .featured-title h5,
.dark-bg .featured-desc,
.theme-bg .featured-title h5,
.theme-bg .featured-desc,
.theme-bg .featured-icon i {
  color: #ffffff;
}
.grey-bg .featured-desc p {
  color: #1c1d3e;
}
.featured-item.style-1 {
  box-shadow: none;
}
.featured-item .featured-icon i {
  color: #00b9c8;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
}
.featured-item .featured-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
}
.featured-item .featured-icon span::after,
.featured-item .featured-icon span::before {
  border-radius: 50%;
  content: '';
  height: 60px;
  position: absolute;
  width: 60px;
  z-index: -1;
}
.featured-item .featured-icon span::after {
  background: #cdf3f6;
  height: 40px;
  left: -10px;
  top: 5px;
  width: 40px;
}
.featured-item .featured-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}

.featured-item::before {
  transform: scale(0);
  background: rgb(0, 185, 199);
  background: linear-gradient(180deg, rgba(0, 185, 199, 1) 0%, rgba(0, 186, 199, 1) 80%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.featured-item:hover::before {
  transform: scale(1);
}

.featured-item:after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  background: url(../images/pattern/04.png) no-repeat 0 0;
  background-size: cover;
}
.featured-item:hover:after {
  opacity: 0.2;
}

.featured-item:hover .featured-icon i,
.featured-item:hover h5,
.featured-item:hover p {
  color: #ffffff;
}
.featured-item.style-1:hover .featured-icon i,
.featured-item.style-2:hover .featured-icon i,
.featured-item.style-6:hover .featured-icon i {
  color: #005bea;
}
.featured-item.style-2:hover h5,
.featured-item.style-6:hover h5 {
  color: #1c1d3e;
}
.featured-item.style-2:hover p,
.featured-item.style-6:hover p {
  color: #5f5f5f;
}

.featured-item.style-2 .featured-icon i {
  font-size: 30px;
  line-height: 40px;
  z-index: 1;
  position: relative;
  float: left;
}
.featured-item.style-2 {
  padding: 20px;
  overflow: inherit;
}
.featured-item.style-2:hover::before {
  display: none;
}

.featured-item.style-3 {
  background: #ffffff;
}

.featured-item.style-4 {
  background: #ffffff;
  border-radius: 70% 100% 30% 70%;
  overflow: hidden;
  padding: 70px 70px 70px 50px;
  z-index: 9;
}

.featured-item.style-5 {
  padding-right: 160px;
  background: #ffffff;
}
.featured-item.style-5 .featured-icon {
  position: absolute;
  top: 40px;
  right: 30px;
}
.featured-item.style-5 h5,
.featured-item.style-6 h5 {
  margin-top: 0;
}

.featured-item.style-6 {
  padding: 0 100px 0 0;
  box-shadow: none;
  overflow: inherit;
}
.featured-item.style-6::before,
.featured-item.style-6::after {
  display: none;
}
.featured-item.style-6 .featured-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.featured-item.style-6 .featured-icon i,
.featured-item.style-1 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-6 .featured-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  z-index: 10;
  border: 2px dashed transparent;
}
.featured-item.style-6:hover .featured-icon i {
  background: none;
}
.featured-item.style-6:hover .featured-icon i::after {
  -webkit-animation: rotateme 9s linear infinite;
  -moz-animation: rotateme 9s linear infinite;
  animation: rotateme 9s linear infinite;
  border-color: #00b9c8;
}

/* ------------------------
    Button
------------------------*/
.btn {
  padding: 12px 25px;
  font-weight: 500;
  background: none;
  color: #1c1d3e;
  overflow: hidden;
  border-radius: 7px;
  border: none;
  position: relative;
  z-index: 9;
}
.btn-sm {
  padding: 10px 20px;
}
.dark-bg .btn,
.theme-bg .btn {
  color: #ffffff;
}
.btn.focus,
.btn:focus {
  box-shadow: none;
}
.btn:hover {
  background: #00b9c8;
  color: #fff;
  transform: translateY(-3px);
}
.btn.btn-theme {
  background: rgb(0, 185, 199);
  background: linear-gradient(90deg, rgba(0, 185, 199, 1) 0%, rgba(0, 186, 199, 1) 80%);
  color: #ffffff;
}
.btn.btn-dark {
  background: #02060b;
  color: #ffffff;
}
.btn.btn-dark:hover {
  background: #00b9c8;
}
.btn.btn-border {
  border: 2px solid rgba(0, 0, 0, 0.05);
}
.dark-bg .btn.btn-border,
.theme-bg .btn.btn-border {
  border: 2px solid rgba(255, 255, 255, 0.2);
}
.btn.btn-circle {
  border-radius: 30px;
}
.btn.btn-white {
  background: #ffffff;
  color: #1c1d3e;
}
.btn.btn-white.btn-border {
  border-color: #ffffff;
  background: none;
  color: #ffffff;
}
.btn.btn-white:hover {
  background: #ffffff;
  color: #005bea;
}
.btn.btn-white.btn-border:hover {
  color: #005bea;
}
.btn + .btn {
  margin-right: 10px;
}
.btn-simple {
  color: #1c1d3e;
  padding: 0;
  background: none !important;
  box-shadow: none;
}
.btn.btn-simple::before {
  padding: 0;
}
.dark-bg .btn-simple,
.theme-bg .btn-simple {
  color: #ffffff;
}
.btn-simple:hover {
  color: #005bea;
  box-shadow: none;
  background: none;
}
.theme-bg .btn-simple:hover {
  color: #1c1d3e;
}

.btn span {
  display: inline-block;
  opacity: 0;
  -webkit-transform: translate3d(10, 10px, 0);
  -moz-transform: translate3d(10, 10px, 0);
  -ms-transform: translate3d(10, 10px, 0);
  -o-transform: translate3d(10, 10px, 0);
  transform: translate3d(10, 10px, 0);
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}

.btn::before {
  content: attr(data-text);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s cubic-bezier(0.75, 0, 0.125, 1) 0s;
  width: 100%;
  padding: 12px 0;
}
.btn:hover:before {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  -o-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.btn:hover span {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.btn:hover span:nth-child(1) {
  -webkit-transition-delay: 0.01s;
  -moz-transition-delay: 0.01s;
  -o-transition-delay: 0.01s;
  transition-delay: 0.01s;
}
.btn:hover span:nth-child(2) {
  -webkit-transition-delay: 0.05s;
  -moz-transition-delay: 0.05s;
  -o-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.btn:hover span:nth-child(3) {
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.btn:hover span:nth-child(4) {
  -webkit-transition-delay: 0.15s;
  -moz-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.btn:hover span:nth-child(5) {
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.btn:hover span:nth-child(6) {
  -webkit-transition-delay: 0.25s;
  -moz-transition-delay: 0.25s;
  -o-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.btn:hover span:nth-child(7) {
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.btn:hover span:nth-child(8) {
  -webkit-transition-delay: 0.35s;
  -moz-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
  transition-delay: 0.35s;
}
.btn:hover span:nth-child(9) {
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.btn:hover span:nth-child(10) {
  -webkit-transition-delay: 0.45s;
  -moz-transition-delay: 0.45s;
  -o-transition-delay: 0.45s;
  transition-delay: 0.45s;
}
.btn:hover span:nth-child(11) {
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.btn:hover span:nth-child(12) {
  -webkit-transition-delay: 0.55s;
  -moz-transition-delay: 0.55s;
  -o-transition-delay: 0.55s;
  transition-delay: 0.55s;
}
.btn:hover span:nth-child(13) {
  -webkit-transition-delay: 0.6s;
  -moz-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.btn:hover span:nth-child(14) {
  -webkit-transition-delay: 0.65s;
  -moz-transition-delay: 0.65s;
  -o-transition-delay: 0.65s;
  transition-delay: 0.65s;
}
.btn:hover span:nth-child(15) {
  -webkit-transition-delay: 0.7s;
  -moz-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
.btn:hover span:nth-child(16) {
  -webkit-transition-delay: 0.75s;
  -moz-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
}
.btn:hover span:nth-child(17) {
  -webkit-transition-delay: 0.8s;
  -moz-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.btn:hover span:nth-child(18) {
  -webkit-transition-delay: 0.85s;
  -moz-transition-delay: 0.85s;
  -o-transition-delay: 0.85s;
  transition-delay: 0.85s;
}
.btn:hover span:nth-child(19) {
  -webkit-transition-delay: 0.95s;
  -moz-transition-delay: 0.95s;
  -o-transition-delay: 0.95s;
  transition-delay: 0.95s;
}
.btn:hover span:nth-child(20) {
  -webkit-transition-delay: 1s;
  -moz-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.icon-btn {
  background: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 0;
  text-align: center;
  line-height: 50px;
  position: absolute;
  left: 50%;
  bottom: -25px;
  margin-left: -25px;
}
.icon-btn:hover {
  background: #00b9c8;
  color: #ffffff;
}

/* ------------------------
    Price Table
------------------------*/
.price-table {
  padding: 30px 20px;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  background: #ffffff;
  text-align: center;
}
.price-title {
  text-transform: uppercase;
  font-weight: 700;
  color: #00b9c8;
}
.price-header {
  position: relative;
  z-index: 9;
}
.price-value {
  display: inline-block;
  width: 100%;
}
.price-value h2 {
  font-size: 60px;
  line-height: 60px;
  font-weight: 800;
  color: #1c1d3e;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
}
.price-value h2 span {
  font-size: 26px;
  left: -15px;
  line-height: 24px;
  margin: 0;
  position: absolute;
  top: 10px;
  color: #5f5f5f;
  font-weight: normal;
}
.price-value span {
  margin: 15px 0;
  display: block;
}
.price-list ul li {
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.price-list ul li:last-child {
  margin-bottom: 0;
}
.dark-bg .price-list ul li {
  color: rgba(255, 255, 255, 0.8);
}
.price-list ul li:last-child {
  margin-right: 0;
}
.price-list li i {
  line-height: 20px;
  font-size: 20px;
}

.price-inside {
  font-size: 80px;
  line-height: 80px;
  position: absolute;
  left: 85%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  font-weight: 900;
  color: rgba(0, 0, 0, 0.04);
}
.price-table::before {
  background: #fafaff;
  content: '';
  height: 300px;
  left: -25%;
  position: absolute;
  top: -10%;
  transform: rotate(-10deg);
  width: 150%;
}
.price-table.active::before {
  transform: rotate(10deg);
}

.price-table.style-2 {
  background: rgba(255, 255, 255, 0.02);
  box-shadow: none;
}
.price-table.style-2::before {
  background: rgba(255, 255, 255, 0.03);
  top: 50%;
  transform: translateY(-50%) rotate(-10deg);
}
.price-table.style-2 .price-title {
  color: #005bea;
}
.price-table.style-3.active .price-title {
  color: #ffffff;
}
.price-table.style-2 .price-value h2,
.price-table.style-3.active .price-value h2 {
  color: #ffffff;
}
.price-table.style-2 .price-list {
  margin-top: 20px;
}
.price-table.style-2.active::before {
  transform: rotate(0);
}
.price-table.style-2 .price-inside {
  color: rgba(255, 255, 255, 0.04);
}
.price-table.style-2 .btn,
.price-table.style-3.active .btn {
  -webkit-box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

.price-table.active {
  padding: 70px 30px;
}
.price-table.style-3.active {
  background: #00b9c8;
  color: #ffffff;
}
.price-table.active .price-value h2 span {
  color: #ffffff;
}
.price-table.style-3:before,
.price-table.style-4:before {
  display: none;
}
.price-table.style-3 .price-list {
  margin-top: 25px;
}

.price-table.style-4 .price-list {
  margin: 25px 0;
}
.price-table.style-4 h3 {
  text-transform: capitalize;
}

/* ------------------------
    Section Title
------------------------*/
.title {
  position: relative;
  color: #1c1d3e;
  margin-bottom: 0;
}
.dark-bg .title,
.theme-bg .title,
.theme-bg .section-title h6 {
  color: #ffffff;
}
.section-title h6 {
  padding: 15px 0 15px 15px;
  position: relative;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.section-title h2 {
  margin-bottom: 15px;
}
.section-title p {
  margin-bottom: 0;
}
.title-effect {
  width: 50px;
  height: 50px;
  top: 0;
  position: absolute;
  right: 0;
  opacity: 0.2;
  animation: rotation 12.8s steps(1) 0s infinite;
}
.text-center .title-effect {
  right: 50%;
  margin-right: -25px;
}
.text-center .section-title h6 {
  padding: 15px 0;
}

.title-effect .bar {
  background: #00b9c8;
}
.title-effect .bar-top {
  width: 100%;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left top;
  transform: scale(0, 1);
  animation: bar-top 3.2s linear 0s infinite;
}
.title-effect .bar-right {
  width: 7px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: left top;
  transform: scale(1, 0);
  animation: bar-right 3.2s linear 0s infinite;
}
.title-effect .bar-bottom {
  width: 100%;
  height: 7px;
  position: absolute;
  right: 0;
  bottom: 0;
  transform-origin: right top;
  transform: scale(0, 1);
  animation: bar-bottom 3.2s linear 0s infinite;
}
.title-effect .bar-left {
  width: 7px;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  transform-origin: left bottom;
  transform: scale(1, 0);
  animation: bar-left 3.2s linear 0s infinite;
}

.title-effect-2 {
  height: 50px;
  position: relative;
  width: 50px;
  margin-bottom: 15px;
  animation: inherit;
  opacity: 1;
}
.title-effect-2 .ellipse {
  width: 100%;
  height: 100%;
  background: #00b9c8;
  border-radius: 50%;
  animation: ellipse-animation 2.4s cubic-bezier(0, -0.26, 0.32, 1.22) 0s infinite;
  transform: rotate(0deg);
}
.title-effect-2 i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  font-size: 20px;
}
.theme-bg .title-effect .bar,
.theme-bg .title-effect-2 .ellipse {
  background: #ffffff;
}
.theme-bg .title-effect-2 i {
  color: #00b9c8;
}

/* ------------------------
    Progress Bar
------------------------*/
.ht-progress-bar {
  margin: 20px 0;
}
.ht-progress-bar h4 {
  color: #2c2c2c;
  font-weight: 700;
  font-size: 16px;
}
.ht-progress-bar .progress {
  height: 30px;
  padding: 4px;
  background: #eceff8;
  overflow: visible;
  border-radius: 30px;
}
.ht-progress-bar .progress .progress-bar {
  background-color: #00b9c8;
  position: relative;
  overflow: visible;
  border-radius: 30px;
}
.ht-progress-bar .progress-parcent {
  position: absolute;
  left: 5px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.theme-bg .ht-progress-bar .progress {
  background: rgba(255, 255, 255, 0.2);
}
.dark-bg .ht-progress-bar .progress {
  background: #2ecc71;
}
.theme-bg .ht-progress-bar .progress .progress-bar,
.dark-bg .ht-progress-bar .progress .progress-bar {
  background: #ffffff;
}
.theme-bg .ht-progress-bar h4,
.theme-bg .ht-progress-bar .progress-parcent,
.dark-bg .ht-progress-bar h4,
.dark-bg .ht-progress-bar .progress-parcent {
  color: #ffffff;
}

/*added by nasiri*/
.featured-item .featured-icon img {
  width: 100px;
  line-height: 80px;
  z-index: 1;
  position: relative;
}
