.lightbox {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    background:#000000d9;
    z-index: 999999999999;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
}

.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
}
.close:hover {
    opacity: 1;
}
.close:before,
.close:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: white;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}
