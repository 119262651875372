ion-toast {
  z-index: 99999;
}

.pincode-input-container {
  input[type=tel] {
    -webkit-text-security: disc;
  }
}

#pinModal {
  ion-list {
    padding-top: 25vh;
  }
  ion-label {
    overflow: visible;
  }
}
