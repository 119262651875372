#login-page,
#signup-page,
#support-page {
  .login-logo {
    padding: 40px 0 0;
    min-height: 200px;
    text-align: center;
  }

  .login-logo img {
    max-width: 200px;
    height: auto;
  }

  .list {
    margin-bottom: 0;
  }

  [type='submit'] {
    -webkit-appearance: initial;
  }
}

.ayria-theme {
  #login-page,
  #signup-page,
  #support-page {
    .login-logo {
      min-height: initial;
      img {
        max-width: 150px;
      }
    }
  }
}

#password-container {
  * {
    font-family: sans-serif !important;
  }
}
