.dropdown-toggle::after {
  display: inline-block;
  margin-right: 15px;
  vertical-align: 0;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-item {
  color: #00b9c8;
}

.acceptor-item {
  padding-right: 10px !important;
}
