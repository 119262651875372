.box {
  display: flex;
  justify-content: space-around;
}

#my-club-acceptors {
  ion-avatar {
    width: 100% !important;
    height: 100% !important;
    max-width: 60px !important;
    max-height: 60px !important;
    border: 0.5px solid var(--ion-color-primary) !important;
    margin: 7px;
  }
}

.font-50 {
  font-size: 50%;
}
