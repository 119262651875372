.platformTheme.bg-info {
  background: #03196e !important;
}
.btn-theme-platform {
  background: #03196e !important;
}
.btn-theme-platform.bg-success:hover {
  background-color: #ffcc00 !important;
}
.btn-theme-platform-2 {
  background-color: #ffcc00 !important;
}
.text-theme-platform {
  color: #03196e !important;
}
.form-group {
  text-align: right;
}
.header-row-platform {
  border-bottom: 1px solid #03196e !important;
  background: #efeff4 !important;
  font-size: 17px;
  color: #092058;
  letter-spacing: 0;
  clear: both;
  min-height: 60px;
  padding: 0 15px;
}
#ayria-header.platform {
  border-radius: 0 !important;
}
.acceptorDropDown {
  direction: rtl;
  text-align: right;
  padding-top: 10px;
  display: inline-block !important;
}
.mobile-only .ads-holder {
  border-radius: 0px !important;
}
.platform-dashboard .ads-holder img {
  border-radius: 0px !important;
}
.box-header {
  border: 2px solid var(--ion-color-primary);
  padding: 7px !important;
  margin: 2px;
  color: #03196e;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
}
.box-header.active {
  border: 2px solid #277e09 !important;
  color: #277e09 !important;
}
.panel-holder {
  border-top: 1px solid #dddddd;
  padding: 10px 5px;
  margin: 5px 5px;
}
.report-tab {
  //font-weight: bold;
  padding: 10px 13px;
  float: right;
  border: 1px solid #7b7b7b;
  border-radius: 13px;
  //padding: 10px;
  color: #7b7b7b;
  cursor: pointer;
}
.report-tab:hover {
  color: #0019e9;
}
.report-tab.active {
  color: #0019e9;
  border: 1px solid #0019e9;
}
.platform-dashboard .reportHolder-style {
  border-radius: 0px !important;
  border: 0 !important;
  padding: 0 !important;
  background: none;
}
.platform-dashboard .gridHeader {
  background-color: #0019e9;
}
.platform-dashboard .btn-theme {
  background: #0019e9 !important;
}
@media (max-width: 767px) {
  .report-tab {
    font-size: 11px;
    padding: 5px;
  }
}
