.originalClassImageGallery {
  margin: 0 10px;
  .image-gallery-image {
    height: 140px;
    object-fit: cover !important;
    border-radius: 20px;
    overflow: hidden;
  }
}

.dep-logo {
  width: 50px !important;
  height: 50px !important;
  clear: both;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-wrapper {
  transform: unset !important;
  width: 100% !important;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.swiper-slide {
  margin-left: unset !important;
}

.scrollMenu {
  display: flex;
  //flex-direction: row;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}
