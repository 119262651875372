/* ------------------------------------------------
  Project:   Softino - Software Landing Page HTML5 Template
  Author:    ThemeHt
------------------------------------------------ */

/* ------------------------
    Responsive Css
------------------------*/

@media (max-width: 1200px) {
  .rslides {
    display: block;
  }

  .rslides-mobile {
    display: none;
  }
  h1 {
    font-size: 50px;
    line-height: 60px;
  }
  h2 {
    font-size: 34px;
    line-height: 44px;
  }
  h3 {
    font-size: 26px;
    line-height: 36px;
  }
  h4 {
    font-size: 22px;
    line-height: 30px;
  }
  h5 {
    font-size: 19px;
    line-height: 28px;
  }
  h6 {
    font-size: 17px;
    line-height: 22px;
  }

  /*Padding*/
  .lg-pl-5 {
    padding-left: 50px !important;
  }
  .lg-pr-5 {
    padding-right: 50px !important;
  }
  .lg-px-3 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  /*Margin*/
  .lg-mt-3 {
    margin-top: 30px !important;
  }
  .lg-mt-5 {
    margin-top: 50px !important;
  }
  .lg-mt-7 {
    margin-top: 70px !important;
  }

  /*Header*/
  .logo img {
    height: 50px;
  }
  #header-wrap.fixed-header .logo img {
    height: 45px;
  }
  .navbar-nav .nav-item {
    margin: 0 10px;
  }
  .navbar-nav .nav-link {
    padding: 8px 5px !important;
  }
  .hero-bg {
    right: -15%;
  }

  /*Counter*/
  .counter span {
    font-size: 40px;
    line-height: 40px;
  }
  .counter h5 {
    font-size: 18px;
    margin: 0;
  }
  .counter.style-4 {
    height: 11.32em;
  }

  /*Feature*/
  .featured-item.style-3 {
    padding: 30px 15px;
  }
  .featured-item.style-3 p {
    font-size: 13px;
  }
  .featured-item.style-4 {
    padding: 40px 30px 40px 30px;
  }

  /*Blog*/
  .post .post-desc {
    padding: 20px 20px 30px;
  }
  .post-title::before {
    right: -20px;
  }
  .post-title::after {
    right: 140px;
  }
  .post-meta ul li {
    font-size: 12px;
    margin: 0 0 0 5px;
  }

  /*Owl*/
  .owl-carousel.owl-nav2 .owl-nav {
    left: -50%;
  }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    padding: 10px 50px !important;
    font-size: 30px;
  }

  /*Other*/
  #svg-container {
    width: 85%;
  }
  .contact-media {
    min-height: 300px;
  }
  .error-page {
    font-size: 300px;
    line-height: 210px;
  }
  .span_user {
    max-width: 150px !important;
  }
}

@media (max-width: 992px) {
  .rslides {
    display: none;
  }
  .rslides-mobile {
    display: block !important;
  }
  h1 {
    font-size: 40px;
    line-height: 50px;
  }
  h2 {
    font-size: 30px;
    line-height: 40px;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
  }
  h4 {
    font-size: 20px;
    line-height: 26px;
  }
  h5 {
    font-size: 18px;
    line-height: 28px;
  }
  h6 {
    font-size: 17px;
    line-height: 26px;
  }

  /*Margin*/
  .md-mt-0 {
    margin-top: 0 !important;
  }
  .md-pl-0 {
    padding-left: 0 !important;
  }
  .md-mt-3 {
    margin-top: 30px !important;
  }
  .md-mt-5 {
    margin-top: 50px !important;
  }
  .md-mt-7 {
    margin-top: 70px !important;
  }
  .md-mb-3 {
    margin-bottom: 30px !important;
  }
  .md-mb-5 {
    margin-bottom: 50px !important;
  }
  .md-mb-7 {
    margin-bottom: 70px !important;
  }
  .md-px-3 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .md-py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .md-px-5 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .md-py-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .md-py-5 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .md-py-8 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .md-pt-8 {
    padding-top: 80px !important;
  }
  .md-pb-15 {
    padding-bottom: 150px !important;
  }

  .md-custom-mt-10 {
    z-index: 99;
    position: relative;
    margin-top: -100px;
  }

  /*Text Align*/
  .md-text-center {
    text-align: center !important;
  }
  .md-text-left {
    text-align: left !important;
  }

  /*Header*/
  .header-1 #header-wrap {
    padding: 15px 20px;
  }
  .navbar {
    justify-content: flex-end;
    width: 100%;
    border-radius: 0 !important;
  }
  .navbar-brand {
    right: 0;
    margin-right: 0;
    position: absolute;
    top: 50%;
    padding: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
  }
  .navbar-toggler {
    background: none;
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 0;
    margin-left: 15px;
  }
  .navbar-nav ul {
    position: static;
  }
  .navbar-nav .nav-item {
    margin: 0;
  }
  .navbar-nav .nav-link {
    padding: 10px !important;
    font-size: 15px;
    border-radius: 0;
  }
  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-item .nav-link.active {
    padding-right: 30px !important;
  }
  .navbar-nav .nav-link::before {
    bottom: inherit;
    top: 0;
    right: 0;
    line-height: 1em;
    transform: inherit;
  }
  .navbar-nav .nav-link:hover::before,
  .navbar-nav .nav-link:focus::before {
    color: #00b9c8;
    text-shadow: 10px 0 #1c1d3e, -10px 0 #1c1d3e;
  }
  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-link.active,
  .nav-item .highlighted {
    padding-right: 30px !important;
  }
  .navbar-nav a .sub-arrow {
    position: absolute;
    top: 50%;
    left: 0;
    margin: -0.7em 0.5em 0 0;
    border-radius: 0.25rem;
    padding: 0;
    width: 2em;
    height: 1.4em;
    font-size: 1.25rem;
    line-height: 1.2em;
    text-align: center;
  }

  .header-3 .navbar-nav .nav-link {
    color: #1c1d3e;
  }
  .header-3 .navbar-nav .nav-link:hover,
  .header-3 .navbar-nav .nav-item .nav-link.active {
    color: #00b9c8;
  }
  .header-3 .navbar-nav .nav-link:hover::before,
  .header-3 .navbar-nav .nav-item .nav-link.active::before,
  .header-3 .navbar-nav .nav-link:focus::before {
    color: #00b9c8;
  }

  .navbar-nav.sm-collapsible .has-submenu {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-collapse {
    background: #ffffff;
    max-height: 400px;
    overflow: auto;
    border: medium none;
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -moz-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    left: 0;
    padding: 10px;
    position: absolute;
    z-index: 99;
    top: 40px;
    width: 100%;
  }

  .navbar-toggler span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #00b9c8;
    transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
    transition-delay: 0.2s;
  }
  .navbar-toggler span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
  }
  .navbar-toggler span:nth-of-type(2) {
    opacity: 0;
  }
  .navbar-toggler span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
  }
  .navbar-toggler[aria-expanded='false'] span {
    transform: none;
    opacity: 1;
    margin: 5px auto;
  }
  .navbar-toggler span {
    margin: 0 auto;
  }
  .header-3 .navbar-toggler span {
    background: #ffffff;
  }

  /*Banner*/
  .fullscreen-banner {
    /*height: auto !important;*/
    padding: 150px 0 100px !important;
  }
  .fullscreen-banner .align-center {
    position: inherit;
    transform: inherit;
    padding-top: 0 !important;
  }

  .page-title {
    padding: 160px 0;
  }
  .page-title h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .fullscreen-banner .img-side {
    position: relative !important;
    transform: inherit;
    top: inherit;
    left: inherit;
  }
  .hero-bg {
    left: 0;
  }

  /*Side Image*/
  .side-image {
    display: none;
  }
  .img-side.img-right {
    right: auto;
    position: relative !important;
    right: 0;
  }
  .img-side.img-left {
    right: 0;
    position: relative !important;
    right: auto;
  }
  .image-column {
    right: 0;
    position: relative;
    top: inherit;
    transform: inherit;
  }

  /*Step*/
  .work-process .box-loader {
    display: none;
  }
  .step-desc p {
    max-width: 300px;
    margin: 0 auto;
  }

  /*Feature*/
  .featured-item.style-3 p {
    font-size: 15px;
  }
  .featured-item.style-4 {
    padding: 50px 60px 50px 60px;
  }

  /*Blog*/
  .post-meta ul li {
    font-size: 14px;
  }
  .post.style-2 .post-desc {
    padding: 10px;
  }

  /*Testimonial*/
  .testimonial-carousel.testimonial-2 .controls li {
    width: 80px;
    height: 80px;
  }
  .testimonial-content p {
    font-size: 16px;
    line-height: 30px;
  }
  .testimonial.style-3 {
    display: inline-block;
  }
  .testimonial.style-3 .testimonial-img {
    width: 30%;
  }
  .testimonial.style-5 .testimonial-content {
    margin-left: -60px;
    padding: 30px 30px 30px 90px;
  }

  /*Hexagon*/
  .hexagon-bg {
    display: inline-block;
  }
  .hexagon {
    width: 25em;
    height: 15.32em;
    margin-bottom: 100px;
    display: inline-block;
  }

  /*Countdown*/
  .countdown > li span {
    font-size: 50px;
    line-height: 50px;
  }
  .countdown > li p {
    font-size: 18px;
  }

  /*Other*/
  .morph-wrap {
    top: 20%;
    left: 0;
    bottom: inherit;
    transform: rotate(0);
  }
  .contact-media {
    min-height: inherit;
  }
  .error-page {
    font-size: 250px;
    line-height: 180px;
  }
  .error-page > span:nth-of-type(2) {
    width: 180px;
    height: 90px;
  }
  .owl-carousel.owl-nav2 .owl-nav {
    left: inherit;
    bottom: inherit;
    position: inherit;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  #svg-container {
    width: 100%;
    transform: rotate(90deg);
    left: 0;
    top: 50%;
  }
  .span_user {
    max-width: 150px !important;
  }
}

@media (max-width: 767px) {
  .rslides {
    display: none;
  }

  .rslides-mobile {
    display: block !important;
  }

  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .hidden-sm {
    display: none !important;
  }
  .section-title h2 {
    font-size: 34px;
    line-height: 40px;
  }
  .section-title p {
    font-size: 16px;
  }
  .page-title h1 {
    font-size: 30px;
    line-height: 30px;
  }

  /*Margin*/
  .sm-mt-0 {
    margin-top: 0 !important;
  }
  .sm-mt-2 {
    margin-top: 20px !important;
  }
  .sm-mt-3 {
    margin-top: 30px !important;
  }
  .sm-mt-5 {
    margin-top: 50px !important;
  }
  .sm-mb-3 {
    margin-bottom: 30px !important;
  }
  .sm-mb-5 {
    margin-bottom: 50px !important;
  }

  /*Padding*/
  .sm-pt-0 {
    padding-top: 0 !important;
  }
  .sm-pt-5 {
    padding-top: 50px !important;
  }
  .sm-pt-10 {
    padding-top: 100px !important;
  }
  .sm-pb-5 {
    padding-bottom: 50px !important;
  }
  .sm-pb-8 {
    padding-bottom: 80px !important;
  }
  .sm-pb-10 {
    padding-bottom: 100px !important;
  }
  .sm-px-3 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .sm-py-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .sm-py-8 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  /*Banner*/
  .sm-bg-cover {
    background-size: cover;
  }

  /*Blog*/
  .post.style-2 .post-desc {
    padding: 20px;
  }

  /*Countdown*/
  .countdown {
    margin: 40px 0;
    padding: 40px 0;
  }
  .countdown > li span {
    font-size: 40px;
    line-height: 40px;
  }
  .countdown > li p {
    font-size: 16px;
  }

  /*Iframe*/
  .iframe-h {
    height: 300px !important;
  }
  .html5-video video {
    width: auto;
  }

  /*Subscribe*/
  .subscribe-form {
    width: 100%;
  }

  /*Testimonial*/
  .testimonial .testimonial-quote {
    font-size: 150px;
    line-height: 150px;
  }
  .testimonial.style-2 .testimonial-img {
    width: 25%;
  }
  .testimonial-2 .controls li:nth-child(1),
  .testimonial-2 .controls li:nth-child(4) {
    bottom: 0;
  }
  .testimonial-2 .controls li:nth-child(2),
  .testimonial-2 .controls li:nth-child(3) {
    bottom: 68%;
  }
  .testimonial.style-3 .testimonial-img {
    width: 50%;
  }
  .testimonial.style-5 {
    display: inline-block;
    margin: 0;
  }
  .testimonial.style-5 .testimonial-img {
    width: 50%;
  }
  .testimonial.style-5 .testimonial-content {
    margin-left: 0;
    padding: 100px 30px 30px 30px;
    margin-top: -100px;
  }

  /*Hexagon*/
  .hexagon-bg {
    top: 5%;
  }
  .hexagon {
    width: 20em;
    height: 12.32em;
    margin-bottom: 50px;
    display: block;
  }

  /*Timeline*/
  .timeline-title {
    text-align: right;
  }
  .timeline::after {
    right: 0;
    margin-right: 0;
  }
  .timeline-inner {
    width: 100%;
  }
  .timeline-right {
    margin-right: 0;
  }
  .timeline-inner::before {
    left: inherit;
    right: -16px;
  }

  /*Error*/
  .error-page {
    font-size: 180px;
    line-height: 180px;
  }
  .error-page > span:nth-of-type(2) {
    width: 140px;
    height: 70px;
  }

  /*Feature*/
  .featured-item.style-4 {
    padding: 50px 110px 50px 110px;
  }

  /*Tab*/
  .tab.style-2 .nav-tabs .nav-link i {
    font-size: 30px;
    line-height: 70px;
    height: 70px;
    width: 70px;
  }
}

@media (max-width: 576px) {
  .span_user {
    max-width: 80px !important;
  }
  .rslides {
    display: none;
  }

  .rslides-mobile {
    display: block !important;
  }

  .col-xx-12 {
    width: 100% !important;
  }
  h1 {
    font-size: 34px;
    line-height: 40px;
  }
  h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
  h4 {
    font-size: 18px;
    line-height: 26px;
  }
  .section-title h2 {
    font-size: 28px;
    line-height: 36px;
  }

  /*Margin*/
  .xs-mt-0 {
    margin-top: 0 !important;
  }
  .xs-mt-1 {
    margin-top: 10px !important;
  }
  .xs-mt-2 {
    margin-top: 20px !important;
  }
  .xs-mt-3 {
    margin-top: 30px !important;
  }
  .xs-mt-5 {
    margin-top: 50px !important;
  }
  .xs-mb-3 {
    margin-bottom: 30px !important;
  }
  .xs-mb-5 {
    margin-bottom: 50px !important;
  }

  /*Padding*/
  .xs-pt-5 {
    padding-top: 50px !important;
  }
  .xs-pb-5 {
    padding-bottom: 50px !important;
  }
  .xs-px-1 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xs-px-2 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xs-px-3 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xs-py-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xs-py-10 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  /*Text Align*/
  .xs-text-center {
    text-align: center;
  }

  /*Header*/
  /*.header a.btn {
       display:none;
    }*/
  .navbar-toggler {
    margin-left: 0;
  }

  /*Banner*/
  .fullscreen-banner p br {
    display: none;
  }
  .breadcrumb-item {
    line-height: 30px;
  }
  .page-title {
    padding: 160px 0 110px;
  }
  .page-title-pattern {
    bottom: -2px;
  }
  .hero-bg {
    overflow: hidden;
  }
  .hero-bg img {
    max-width: inherit;
    width: 170%;
  }
  .round-shape {
    width: 250px;
    height: 250px;
  }

  /*Button*/
  .btn {
    padding: 10px 15px;
  }
  .btn + .btn {
    margin-left: 5px;
  }

  /*Step*/
  .work-process.style-2 {
    display: block;
  }
  .work-process.style-2 .step-num-box {
    margin-bottom: 30px;
    margin-right: 0;
  }

  /*Countdown*/
  .countdown {
    margin: 30px 0;
  }
  .countdown > li {
    padding: 0 10px;
  }
  .countdown > li p {
    font-size: 14px;
    line-height: 24px;
  }

  /*Blog*/
  .post.style-2 {
    margin: 0;
  }
  .post.style-2 .post-desc {
    padding: 10px;
  }
  .post.style-2 p {
    font-size: 12px;
  }
  .post.style-2 .post-title {
    padding-top: 15px;
    margin-top: 15px;
  }
  .media-holder .media {
    display: block;
    padding: 20px;
  }
  .media-holder .media img {
    margin-right: 0 !important;
    margin-bottom: 15px;
  }
  .media-holder .media.inner {
    margin: 15px 0 15px 30px;
  }
  .comment-reply {
    position: inherit;
    top: inherit;
    right: inherit;
    margin-top: 20px;
  }

  /*Feature*/
  .featured-item.style-4 {
    padding: 50px 30px 50px 30px;
    border-radius: 50% 60% 30% 40%;
  }
  .featured-item.style-5 {
    padding: 40px 30px;
  }
  .featured-item.style-5 .featured-icon {
    position: inherit;
    top: inherit;
    left: inherit;
    margin-bottom: 20px;
  }

  /*Team*/
  .team-member.style-1:hover .team-description {
    padding: 25px 20px;
  }
  .team-member.style-2 .team-description {
    padding: 25px 15px;
  }
  .team-member.style-3 {
    padding: 25px;
  }
  .team-member.style-4 .team-images {
    width: 100%;
  }
  .team-member.style-4 .team-description {
    width: 100%;
    position: inherit;
    top: inherit;
    transform: inherit;
    margin-top: -100px;
  }
  .team-member.style-4:hover .team-description {
    position: inherit;
    top: inherit;
  }

  /*Testimonial*/
  .testimonial.style-1 .testimonial-content {
    margin-right: 20px;
  }
  .testimonial-content p {
    font-size: 16px;
    line-height: 28px;
  }
  .testimonial.style-2 {
    padding: 20px 10px 70px;
  }
  .testimonial.style-2 .testimonial-img {
    width: 50%;
  }
  .testimonial-carousel.testimonial-2 .controls,
  .testimonial-carousel.testimonial-3 .controls {
    position: relative;
    margin-top: 30px;
  }
  .testimonial-carousel.testimonial-2 .controls li {
    width: 100px;
    height: 100px;
    position: relative;
    bottom: inherit;
    left: inherit;
    right: inherit;
    margin: 0 20px 20px;
  }
  .testimonial-carousel.testimonial-2 .controls li:nth-child(3n),
  .testimonial-carousel.testimonial-2 .controls li:last-child {
    margin-bottom: 0;
  }
  .testimonial-carousel.testimonial-3 .controls li {
    position: relative;
    bottom: inherit;
    left: inherit;
    margin-right: 20px;
  }
  .testimonial-carousel.testimonial-3 .controls li:last-child {
    margin-right: 0;
  }

  /*Counter*/
  .counter.style-2 .counter-icon {
    width: 25%;
  }

  /*Error*/
  .error-page {
    font-size: 110px;
    line-height: 110px;
  }
  .error-page > span:nth-of-type(2) {
    width: 100px;
    height: 50px;
  }

  /*Tab*/
  .tab .nav-tabs {
    display: block;
  }
  .tab.style-2 .nav-tabs .nav-link {
    display: inline-block;
    width: 48%;
    margin-right: 2%;
  }
  .tab.style-2 .nav-tabs .nav-link:nth-child(2n + 2) {
    margin-right: 0;
  }
  .tab.style-2 .tab-content {
    padding: 40px 20px 20px;
  }

  /*Subscribe*/
  .subscribe-inner {
    padding: 20px 5px;
  }

  /*Other*/
  .blink-img {
    width: 80%;
  }
  .custom-li li {
    width: 100%;
  }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    font-size: 30px;
    margin: 0 5px;
    padding: 6px 40px !important;
  }
  .morph-wrap {
    top: 8%;
  }
}
