.plan-container {
  clear: both;
  padding-right: 30%;
}

.padding {
  padding: 5px;
}

.padding-left {
  padding-left: 15px;
}
