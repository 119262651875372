ion-modal {
  z-index: 9999;
  ion-button {
    font-weight: 700 !important;
    font-size: 1em !important;
  }
}
.modal {
  ion-button {
    font-weight: 700 !important;
    font-size: 1em !important;
  }
}

#ayriaPaymentModalAcceptor {
  .modal-wrapper {
    height: 100vh;
  }
}

.modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: flex-start !important;
}
