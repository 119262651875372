.payment-service-row {
  border: 1px solid #efeff4;
  border-radius: 5px;
  font-size: 13px;
  color: #566a88;
  text-align: center;
  letter-spacing: 0.3px;
  text-align: center;
  padding: 2px;
}

.payment-service-row img:hover {
  transform: scale(1.15);
}

.expire-date-month,
.expire-date-year {
  float: left;
}

.width-100-per {
  width: 100%;
}

.valign-main {
  display: table;
}

.valign-inner {
  display: table-cell;
  vertical-align: middle;
}

.payment-image {
  width: 50px;
  height: 50px;
}

#kala-group-reorder-modal,
#definitions-modal {
  .modal-wrapper {
    height: 100vh;
  }
}
