#pin-button-modal {
  .modal-wrapper {
    height: 100vh;
  }
  #mapId {
    height: 40vh;
  }
  @media (min-width: 660px) {
    #mapId {
      width: 500px;
      height: 500px;
    }
  }
}
