#bank-cards {
  ion-card {
    border-radius: 16px !important;
  }
}
.swiper-pagination-bullet {
  background: var(--ion-color-primary-tint);
}

.bank-cards-form {
  display: inline;
  padding: 1em;
}

.card-fields {
  text-align: left;
  direction: ltr;
}

.table td {
  border-top: none;
}

.float-right {
  float: right;
}

.missing-numbers {
  width: 95px;
  border: none;
  direction: ltr;
  padding-left: 4px;
}

.line-through {
  text-decoration: line-through;
}

.bankImg {
  width: 60px !important;
  padding: 0 !important;
  position: absolute;
  top: 10px;
  right: 10px;
}

.bankCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}

.bankTAT {
  background: linear-gradient(to right, #d3ba08 0%, rgba(255, 255, 255, 0) 89%);
  color: #3c1b0a;
}
.bankEGHTESAD_NOVID {
  background: linear-gradient(to right, #9b1696 0%, rgba(255, 255, 255, 0) 89%);
  color: #ffffff;
}
.bankANSAR {
  background: linear-gradient(to right, #aa8125 0%, rgba(255, 255, 255, 0) 89%);
  color: #190000;
}
.bankIRAN_ZAMIN {
  background: linear-gradient(to right, #ddd8ef 0%, rgba(255, 255, 255, 0) 89%);
  color: #8b41a4;
}
.bankPARSIAN {
  background: linear-gradient(to right, #9b1f15 0%, rgba(255, 255, 255, 0) 89%);
  color: #470101;
}
.bankPASARGAD {
  background: linear-gradient(to right, #000000 0%, rgba(255, 255, 255, 0) 89%);
  color: #d99300;
}
.bankTEJARAT {
  background: linear-gradient(to right, #1ebcc7 0%, rgba(255, 255, 255, 0) 89%);
  color: #ffffff;
}
.bankTOSAE_TAVON {
  background: linear-gradient(to right, #365f97 0%, rgba(255, 255, 255, 0) 89%);
  color: #ffffff;
}
.bankTOSAE_SADERAT {
  background: linear-gradient(to right, #455335 0%, rgba(255, 255, 255, 0) 89%);
  color: #adc39c;
}
.bankHEKMAT_IRANIAN {
  background: linear-gradient(to right, #a0acd6 0%, rgba(255, 255, 255, 0) 89%);
  color: #a0acd6;
}
.bankDAY {
  background: linear-gradient(to right, #009ab6 0%, rgba(255, 255, 255, 0) 89%);
  color: #46474a;
}
.bankREFAH {
  background: linear-gradient(to right, #d2cecf 0%, rgba(255, 255, 255, 0) 89%);
  color: #35379b;
}
.bankSAMAN {
  background: linear-gradient(to right, #02aae8 0%, rgba(255, 255, 255, 0) 89%);
  color: #000000;
}
.bankSEPAH {
  background: linear-gradient(to right, #5b5b5d 0%, rgba(255, 255, 255, 0) 89%);
  color: #f08c1a;
}
.bankSARMAYEH {
  background: linear-gradient(to right, #53358d 0%, rgba(255, 255, 255, 0) 89%);
  color: #ffffff;
}
.bankSINA {
  background: linear-gradient(to right, #965e01 0%, rgba(255, 255, 255, 0) 89%);
  color: #ffffff;
}
.bankSHAHR {
  background: linear-gradient(to right, #ed1b24 0%, rgba(255, 255, 255, 0) 89%);
  color: #ffffff;
}
.bankSADERAT {
  background: linear-gradient(to right, #bec4c0 0%, rgba(255, 255, 255, 0) 89%);
  color: #142366;
}
.bankSANAT_O_MADAN {
  background: linear-gradient(to right, #193780 0%, rgba(255, 255, 255, 0) 89%);
  color: #ffffff;
}
.bankMEHR {
  background: linear-gradient(to right, #02913f 0%, rgba(255, 255, 255, 0) 89%);
  color: #262221;
}
.bankGHAVAMIN {
  background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 89%);
  color: #095f3c;
}
.bankKAR_AFARIN {
  background: linear-gradient(to right, #005836 0%, rgba(255, 255, 255, 0) 89%);
  color: #d2ab67;
}
.bankKESHAVARZI {
  background: linear-gradient(to right, #1f2c13 0%, rgba(255, 255, 255, 0) 89%);
  color: #eeea95;
}
.bankGARDESHGARI {
  background: linear-gradient(to right, #860203 0%, rgba(255, 255, 255, 0) 89%);
  color: #ffffff;
}
.bankMARKAZI {
  background: linear-gradient(to right, #35639f 0%, rgba(255, 255, 255, 0) 89%);
  color: #ffffff;
}
.bankMASKAN {
  background: linear-gradient(to right, #d29524 0%, rgba(255, 255, 255, 0) 89%);
  color: #9b1b0b;
}
.bankMELLAT {
  background: linear-gradient(to right, #f391a0 0%, rgba(255, 255, 255, 0) 89%);
  color: #555557;
}
.bankMELLI {
  background: linear-gradient(to right, #bbb291 0%, rgba(255, 255, 255, 0) 89%);
  color: #3c3429;
}
.bankMEHR_EGHTESAD {
  background: linear-gradient(to right, #ba9b40 0%, rgba(255, 255, 255, 0) 89%);
  color: #4c4334;
}
.bankPOST {
  background: linear-gradient(to right, #00963f 0%, rgba(255, 255, 255, 0) 89%);
  color: #ffffff;
}
.bankTOSAE {
  background: linear-gradient(to right, #455335 0%, rgba(255, 255, 255, 0) 89%);
  color: #adc39c;
}
.bankKOSAR {
  background: linear-gradient(to right, #ededed 0%, rgba(255, 255, 255, 0) 89%);
  color: #893a73;
}
.bankMIDDLE_EAST {
  background: linear-gradient(to right, #ff9136 0%, rgba(255, 255, 255, 0) 89%);
  color: #00438e;
}
.bankOTHER {
  background: linear-gradient(to right, #303030 0%, rgba(255, 255, 255, 0) 89%);
  color: #989898;
}
