#paymentservice-tab-page {
  ion-card {
    margin: 0;
    border-radius: 0;
  }
}

.karafarin-theme,
.dark-theme {
  #paymentservice-tab-page {
    ion-card {
      border: none;
    }
  }
}

.floatingButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  margin: 0;
  background-color: #ffffff;
  border-radius: 50px;
  border: #c0b9b9 solid 1px;
  color: #2f308d;

  &.blue {
    background-color: #2f308d;
    color: #ffffff;
  }
}
