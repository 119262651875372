.pagination {
  display: inline-flex !important;
}
.pagination > li {
  margin: 1px !important;
}

.ellipsis {
  white-space: nowrap;
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
