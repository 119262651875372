#cashDeskDailyReportPage {
  overflow-y: visible;
}
@page {
  size: A4 portrait;
}

.pageBreak {
  page-break-after: always;
  page-break-inside: avoid;
  break-after: always;
}

@media print {
  .printable {
    .DatePicker__input {
      border: 0px;
    }
    font-size: 120%;
  }
}
