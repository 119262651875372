//@import './assets/styles/css/animate.css';
//@import './assets/styles/css/fontawesome-all.css';
//@import './assets/styles/css/line-awesome.min.css';
//@import './assets/styles/magnific-popup/magnific-popup.css';
@import './assets/styles/css/base.css';
@import './assets/styles/css/style.css';
@import './assets/styles/css/responsive.css';
@import './assets/styles/css/shortcodes.css';
@import './assets/styles/css/rotating-card.css';
@import './assets/styles/css/platform-theme/platform.scss';
@import './assets/styles/css/bootstrap.min.css';
@import './assets/styles/css/fontiran.css';

input::placeholder {
  text-align: right;
}
input::-webkit-input-placeholder {
  text-align: right;
}
