@media print {
  #ayriaPayment {
    width: 7cm;
    display: flex;
    justify-content: center;
  }
}

.w-8cm {
  width: 8cm;
}

.w-7cm {
  width: 7cm;
}

.w-6cm {
  width: 6cm;
}

.font-11 {
  font-size: 11px;
}
