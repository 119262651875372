.ion-button-icon-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#qr-tab-page {
  ion-col {
    padding: 0;
  }
  ion-grid {
    padding: 0;
  }
  section {
    padding: 0;
  }
}
