.btn-group-fab {
  position: fixed;
  width: 50px;
  height: auto;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.btn-group-fab div {
  position: relative;
  width: 100%;
  height: auto;
}
.btn-group-fab .btn {
  position: absolute;
  bottom: 0;
  border-radius: 50%;
  display: block;
  margin-bottom: 4px;
  width: 40px;
  height: 40px;
  margin: 4px auto;
}
.btn-group-fab .btn-main {
  width: 50px;
  height: 50px;
  right: 50%;
  z-index: 9;
}
.btn-group-fab .btn-sub {
  bottom: 0;
  z-index: 8;
  right: 50%;
  margin-right: -20px;
  -webkit-transition: all 2s;
  transition: all 0.5s;
}
.btn-group-fab.active .btn-sub:nth-child(2) {
  bottom: 60px;
}
.btn-group-fab.active .btn-sub:nth-child(3) {
  bottom: 110px;
}
.btn-group-fab.active .btn-sub:nth-child(4) {
  bottom: 160px;
}
.btn-group-fab .btn-sub:nth-child(5) {
  bottom: 210px;
}
