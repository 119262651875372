.profile-image-container {
    position: relative;
    top: -90px;
}

.profile-image img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 8px solid white;
}

.profile-name {
    font-size: 30px;
}
