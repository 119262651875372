#lottery-tab-page {
  .lottery-card {
    img {
      width: 100%;
    }
  }
  .insta_address {
    text-align: center;
    width: 100%;

    direction: ltr;
    ion-icon {
      font-size: 16px;
    }
  }
}
