.merchant-profile section {
  display: block;
  padding: 15px;
  border-bottom: 1px solid #ececec;
}
.merchant-profile-banner {
  min-height: 160px;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.merchant-profile-banner_footer {
  background: linear-gradient(0deg, #4e555a, transparent);
  position: absolute;
  width: 100%;
  bottom: 0;
  color: white;
}

.merchant-profile-tabs {
  display: flex;
  justify-content: space-between;
  //position: sticky;
  top: 0;
  background: #4e555a;
  z-index: 999;
}

.merchant-profile-tabs a {
  text-align: center;
  color: white;
}

.merchant-profile-tabs .active {
  color: white;
  border-bottom: 2px solid white;
}

.merchant-profile-tabs button {
  background: none !important;
  border: none;
  color: white;
  margin: auto;
  padding: 5px;
  font-weight: 500;
}

.merchant-profile-tab-content {
  height: 100%;
}

.merchant-profile-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.map-container {
  width: 500px;
}

.merchant-profile-images {
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
}

.merchant-profile-images .image-item {
  border: 1px solid white;
}

.merchant-profile .progress-bar-item {
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
}

.merchant-profile .progress-bar-item .progress {
  width: 100%;
  height: 6px;
  margin-top: 38px;
}

.merchant-profile .progress-bar-item .descrption {
  position: absolute;
  right: -20px;
}

.merchant-profile .progress-bar-item .descrption img {
  position: relative;
  right: -10px;
}

.merchant-profile-statistics {
  display: flex;
  justify-content: space-evenly;
}

.merchant-profile-statistics .rating-statistics-progress-bar {
  width: 40%;
  font-size: 14px;
}

.merchant-profile-statistics .overal-score {
  width: 102px;
  margin: 31px 0;
  align-self: center;
}

.over-img {
  position: absolute;
  top: 80%;
  left: -20%;
}

.whitespace-pre {
  white-space: pre;
}

.custom-ion-card {
  margin: 0;
  box-shadow: none;
  border-radius: 5px;
  width: 100%;
  max-height: 310px;
}
.custom-subtitle {
  font-size: 14px;
  font-weight: 800;
}

button.badge {
  background-color: #f04040;
  border: none;
  color: #ffffff;
  width: 1.5rem;
}
.custom-img {
  border-radius: 10%;
  display: flex;
}

ion-col {
  color: #1f2120;
}
del {
  color: #9b1f15;
}

ion-card-title,
ion-card-subtitle {
  font-size: 17px;
  font-weight: bold !important;
  padding: 0;
  margin: 0;
  text-align: right !important;
}

ion-button {
  --box-shadow: none;
  --border-radius: 12px;
  text-align: left !important;
}

h3 {
  font-weight: 700;
  font-size: 17px;
}

p {
  font-weight: 500;
  font-size: 14px;
}

.basket-shop {
  position: sticky;
  bottom: 0;
}

#shipping-address-page {
  #mapId {
    height: 35vh;
  }
}

#shopping-confirm-address-modal {
  #mapId {
    height: 40vh;
  }
}

.center-avatar {
  width: 100% !important;
  height: 100% !important;
  max-width: 60px !important;
  max-height: 60px !important;
  border: 0.5px solid var(--ion-color-primary) !important;
  margin: 7px;
}

.trash-icon {
  color: #e04055;
  width: 27px;
  height: 27px;
}
#hscrollbar {
  background-color: white !important;
  position: sticky;
  top: 0;
  z-index: 900;

  #groups {
    overflow-x: auto;
    white-space: nowrap;
  }
}
.dark-theme {
  #hscrollbar {
    background-color: black !important;
  }
}

#merchant-profile-map-container {
  #mapId {
    height: 40vh;
  }
  @media (min-width: 660px) {
    #mapId {
      width: 500px;
      height: 500px;
    }
  }
}
