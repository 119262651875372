#ayria-header {
  background: #efeff4;
  border: 1px solid #979797;
  border-radius: 0 0 20px 20px;
  background: #ffffff !important;
  font-size: 17px;
  color: #092058;
  letter-spacing: 0;
  border: 0;
  /*border: 1px solid #092058;*/
  box-shadow: none;
}
.ReactPasswordStrength-strength-desc {
  width: unset !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.header-row {
  background: #efeff4;
  border: 1px solid #979797;
  border-radius: 0 0 20px 20px;
  background: #efeff4 !important;
  font-size: 17px;
  color: #092058;
  letter-spacing: 0;
  /*border: 1px solid #092058;*/
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  clear: both;
  min-height: 45px;
}
#ayria-header .profile-image img {
  border: 0px !important;
  margin-left: 15px;
}
.header-row .profile-image img {
  border: 0px !important;
}
.ads-holder {
  background: #ffffff;
  border-radius: 35px !important;
  color: #8e8e93;
  text-align: center !important;
  overflow: hidden;
}
.ads-holder-desktop {
  border-radius: 35px !important;
  color: #8e8e93;
  text-align: center !important;
  overflow: hidden;
  background-image: url('../../../platform/home-desktop.png') !important;
  background-repeat: repeat-y;
  width: 100%;
  max-height: 80px;
}
.ads-holder-desktop img {
  max-height: 80px;
}
.desktop-only .ads-holder img {
  width: 100%;
  max-height: 80px;
}
.mobile-only .ads-holder {
  width: 100%;
  margin: 0;
}
.mobile-only .ads-holder img {
  margin-bottom: 10px;
}
.vl {
  border-left: 2px solid #c3c3c6;
  height: 30px;
  margin-top: 5px;
}
.gridHeader {
  background-color: rgb(0, 185, 199);
  color: #fff;
}
.notification-icon,
.inbox-icon {
  width: 40px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
}
.notificaiton-item {
  position: relative;
  display: inline-block;
}
.notification-badge {
  position: absolute;
}
.referralLinkHolder {
  border-radius: 8px;
  font-size: 16px;

  letter-spacing: 0.34px;
  text-align: center;

  text-align: center;
  color: #8e8e93;
  letter-spacing: 0.77px;
}
.referralTitle {
  white-space: normal;
  width: 70px;
  font-size: 11px;
  float: right;
  margin-left: 10px;
  margin-top: 5px;
}
.referralCode {
  font-size: 18px;
  float: right;
  margin-left: 20px;
}
.hidden {
  display: none;
}
.btn-header {
  padding: 10px 15px !important;
}
.userDropDOwn a {
  color: #092058;
}
.userDropDOwn a:hover {
  color: #092058;
}
.planHolder {
}
.font-20 {
  font-size: 20px;
}
.dashboard-item {
  border: 1px solid #979797;
  border-radius: 35px !important;
  text-align: right !important;
}
.right {
  float: right;
}
.left {
  float: left;
}
.ltr {
  direction: ltr;
}
.clear-both {
  clear: both;
}
.pointer {
  cursor: pointer;
}
.statistics {
  border: 3px solid;
  border-radius: 8px;
  font-size: 13px;
  color: #8e8e93;
  letter-spacing: 0.64px;
  text-align: right;
  padding: 20px 10px;
  float: left;
  margin-right: 15px;
  margin-left: 15px;
}
.card-numberHolder {
  border: 1px solid #efeff4;
  border-radius: 5px;
  font-size: 20px;
  color: #566a88;
  text-align: center;
  /*opacity: 0.6;*/
  text-align: center;
  letter-spacing: 0.3px;
  text-align: center;
  padding: 15px;
}
.edit-btn {
  background: #f0b130;
  border-radius: 3px;
}
.delete-btn {
  background: #ff3b30;
  border-radius: 3px;
}
.cardList img {
  width: 35px;
  float: right;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.bankImg {
  width: 60px !important;
  padding: 0px !important;
}
.cardList span {
  padding-top: 2px;
}
.btn-green {
  background: #4cd964 !important;
  color: #fff !important;
}
table thead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.react-autosuggest__input {
  height: 40px !important;
  color: #555;
  border-bottom: 2px solid rgba(223, 223, 223, 1);
  width: 100% !important;
}

.search-input {
  border: 1px solid #979797;
  border-radius: 23.5px;
  font-size: 17px;
  color: #010e2f;
}
.input-icons i {
  position: absolute !important;
  padding-top: 15px;
  z-index: 1000;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
  direction: initial;
}

.icon {
  padding: 10px;
  color: green;
  min-width: 50px;
  text-align: left;
  color: #010e2f;
  font-size: 20px;
  float: left;
}

.input-field {
  width: 100%;
  padding: 10px;
  position: inherit;
  direction: rtl;
}
.box-style-01 {
  background: #ff3b30;
  border-radius: 5px;
  font-size: 17px;
  color: #ffffff;
  letter-spacing: 0.36px;
  text-align: center;
  padding: 20px 10px;
}
.box-style-02 {
  background: #ff9500;
  border-radius: 5px;
  font-size: 17px;
  color: #ffffff;
  letter-spacing: 0.36px;
  text-align: center;
  padding: 20px 10px;
}
.box-style-03 {
  background: #4cd964;
  border-radius: 5px;
  font-size: 17px;
  color: #ffffff;
  letter-spacing: 0.36px;
  text-align: center;
  padding: 20px 10px;
}
.dep-box {
  margin: 10px;
}
.percent-box {
  margin: 10px;
}
.temp_acceptor-list {
  padding: 4px;
  border-radius: 2px;
  border: 1px solid #979797;
  margin: 5px;
}

@media (max-width: 1024px) {
  .rslides {
    display: none;
  }

  .rslides-mobile {
    display: block !important;
  }
}
/*added by nasiri*/

.login-minimize {
  display: none;
}

.register-minimize {
  display: none;
}

.accordion .card-body {
  padding: 10px 20px !important;
}
.card {
  border-radius: 0 0 20px 20px !important;
  /*background: #EFEFF4 !important;*/
  color: #092058 !important;
  letter-spacing: 0 !important;
  border: 1px solid #092058 !important;
  border-top: 0px !important;
  margin-bottom: 0 !important;
  padding: 10px 5px !important;
  border-left: none !important;
  border-right: none !important;
  margin-top: 1px;
}
.accordion .card-header {
  text-align: right;

  background: none !important;
  box-shadow: none !important;
  padding-right: 15px !important;
}
.accordion .card {
  box-shadow: 0 1px 1px 1px rgba(39, 39, 39, 0.1);
}
.accordion {
  border-left: 1px solid #092058 !important;
  border-right: 1px solid #092058 !important;
  border-radius: 0 0 20px 20px;
  background: #ffffff !important;
  padding-top: 0px !important;
}
.mobile-header {
  border-left: 1px solid #092058 !important;
  border-right: 1px solid #092058 !important;
  border-radius: 0 0 20px 20px;
}
.filterList .accordion {
  border-left: none !important;
  border-right: none !important;
}
.filterList .card {
  border-radius: 20px !important;
  background: #fff !important;
  color: #092058 !important;
  letter-spacing: 0 !important;
  border: 1px solid #092058 !important;
  padding: 10px 5px !important;
  margin-bottom: 5px;
}
footer {
  display: inline-block !important;
}
/****summery dashboard****/
@media (min-width: 650px) {
  .mobile-only {
    display: none !important;
  }

  .recent-activity-holder {
    overflow-y: auto;
  }
  .scoreHolder {
    border: 2px solid #ff9500;
    border-radius: 8px;
    font-size: 30px;
    color: #8e8e93;
    letter-spacing: 0.64px;
    text-align: center;
    padding: 5px;
    float: left;
    margin-left: 20px;
  }
  .p-container {
    margin: 60px 0 !important;
  }
  .dep-box {
    width: 115px;
  }
}

@media (max-width: 650px) {
  .desktop-only {
    display: none !important;
  }
  .p-container {
    margin: 0 !important;
  }
  .mobile-mb-5 {
    margin-bottom: 3rem !important;
  }

  .recent-activity-holder {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .logo img {
    height: 30px !important;
    margin-top: 5px;
  }
  .img-circle img {
    width: 43px;
  }
  .stats-title {
    font-size: 14px;
  }
  .payment-service-row {
    font-size: 12px;
  }
  .width-57 {
    width: 48px !important;
  }
  .font14 {
    font-size: 12px !important;
  }
  .card-numberHolder {
    font-size: 16px;
  }
  .statistics {
    padding: 10px 5px !important;
    margin: 5px 0 !important;
    min-height: 107px;
  }
  .card-body {
    font-size: 12px;
  }
  .dep-box {
    width: 60px;
  }
  .cardList img {
    width: 30px;
  }
  .percent-box {
    font-size: 13px;
  }
  .header-row {
    font-size: 14px;
  }
  .featured-title {
    display: none !important;
  }
  .dashboard-item {
    padding: 0 !important;
    border: none !important;
    background: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  .referralCode {
    margin-left: 10px;
  }
  .btn-sm {
    font-size: 13px !important;
  }
  .logo.platform img {
    height: 52px !important;
    margin-top: 3px;
  }
  .gridHeader {
    font-size: 14px;
  }
  footer {
    font-size: 8px !important;
  }
}

.introjs-tooltiptext {
  font-size: 20px;
}

.dashboard-tab {
  border: 1px solid rgb(0, 185, 199);
  border-radius: 8px;
  padding: 5px;
  text-align: center;
  margin: 2px;
}
.margin-auto {
  margin: auto;
}

.rating-icon {
  width: 40px;
  margin: 0px 3px;
}
footer {
  font-size: 12px;
}
.userDropDOwn {
  direction: rtl;
  text-align: right;
  padding-top: 10px;
  display: inline-block !important;
}
.btn-card {
  padding: 0 !important;
}
.btn-card:hover {
  background: none !important;
}
.height100 {
  height: 100%;
}
