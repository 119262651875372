#kala-page {
  .top-section {
    text-align: center;
    height: max-content;
  }
  .main-section {
    //background: #f1f5f8;
    padding: 5px;
    margin: 0px 0px 0px 0px;
    ion-row {
      margin-top: 0;
      padding-top: 0px;
    }
    ion-col {
      padding: 0px 15px 0px 15px;
    }
    h2 {
      font-weight: 600;
      font-size: 19px;
      //color:#5a6268;
    }
    h3 {
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.seperator {
  hr {
    position: absolute;
    left: 0;
    width: 100%;
    background: #e2e2e2;
    margin: 0;
    padding: 0;
  }
}

.backbtn {
  margin-left: 15px;
}

.notification {
  margin-right: 15px;
}

.item-name {
  color: #2a2a2a;
  font-size: 20px;
}
.rating {
  display: inline-block;
  color: #ffffff;
  background: var(--ion-color-primary);
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  margin: 0px;
}

.price {
  color: var(--ion-color-primary);
  margin-bottom: 0;
}
.span {
  //color: #2a2a2a;
  color: #e04055;
  text-decoration: line-through;
  margin-left: 20px;
  font-size: medium;
  vertical-align: top;
  font-weight: 900;
}

.description {
  h5 {
    color: #2a2a2a;
    padding-top: 10px;
  }

  p {
    color: #656565;
  }
}
ion-footer {
  ion-row {
    padding: 0;

    ion-col {
      padding: 0;

      ion-button {
        margin: 0;
        height: 50px;
        font-size: 18px;
        box-shadow: none;
      }
    }
  }
}

// This will remove footer shadow on android
.footer-md::before {
  background-image: none !important;
}

.row {
  display: flex;
  justify-content: space-between;
}
.row.center {
  align-items: center;
}
.col-1 {
  flex: 1;
}
.col-2 {
  flex: 2;
}
.block {
  background-color: #e0e0e0;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}
.text-right {
  text-align: right;
}
img.small {
  max-height: 8rem;
}

button.add {
  background-color: #0c5460;
  width: 1.5rem;
}
button.remove {
  background-color: #9b1f15;
  width: 1.5rem;
}
button.badge {
  background-color: #f04040;
  border: none;
  color: #ffffff;
  width: 1.5rem;
}
