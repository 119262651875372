.landing {
  background: white;
  font-family: inherit;
  max-width: 500px;
  margin: 0 auto;
  font-size: 14px;
  overflow-x: hidden;
  a {
    color: #ff006c;
    font-weight: bold;
  }
  button {
    border: none;
    border-radius: 4px;
    color: white;
    padding: 4px 10px;
    min-width: 90px;
    min-height: 40px;
  }
  section {
    padding: 0;
  }
  strong {
    color: #ff006c !important;
    font-weight: bold;
  }
}

.landing__header_container {
  position: relative;
}

.landing__header {
  position: fixed;
  height: 80px;
  max-width: 500px;
  width: 100%;
  z-index: 99999999;
  padding: 20px 0px;
  background-color: var(--ion-color-primary);
}

.landing__header_logo {
  position: absolute;
  top: 10px;
  left: 10px;
  background-image: url('/assets/ayria/logo-small.png');
  height: 65px;
  width: 65px;
  background-size: contain;
  background-repeat: no-repeat;
}

.landing__header_menu {
  list-style: none;
  display: flex;
  margin: 0 auto;
  .item {
    padding: 8px;
    color: white;
  }
  a {
    color: white;
    font-size: 12px;
    font-weight: 100;
  }
  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-size: 12px;
    font-weight: 100;
    margin: 0 !important;
    min-width: inherit;
  }
}

.landing__banner-container {
  position: relative;
  width: 100%;
}

.landing__wave {
  background-image: url('/assets/ayria/landing/wave.png');
  height: 400px;
  background-repeat: no-repeat;
  background-position: bottom center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 9999;
  background-size: 100%;
}

.landing__banner {
  height: 400px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
  position: absolute;
  width: 100%;
  top: 0;
}

.landing__banner_FEMALE {
  /* background-image: url('images/1.png'); */
}

.landing__banner_MALE {
  /* background-image: url('images/10141.png'); */
}

.landing__banner_UNISEX {
  background-image: url('/assets/ayria/landing/main-both.png');
}

.landing__welcome {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 9999;
  top: -24px;
  .signup-button {
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;
    background-color: #d30000;
  }
  .login-button {
    background-color: #17c517;
    font-size: 18px;
    font-weight: bold;
  }
}

.landing__welcome-text {
  background-image: url('/assets/ayria/landing/welcome.png');
  background-repeat: no-repeat;
  height: 130px;
  width: 190px;
  background-size: 100%;
}

.landing__welcome-login-and-signup {
  text-align: left;
  position: relative;
  z-index: 9999;
  left: 14px;
  width: 100px;
}

.landing__special_offer {
  width: 350px;
  position: relative;
  margin: 10px auto;
  height: 150px;
  padding: 0;
  color: #ac0000;
  font-size: 32px;
  text-align: center;
}

.landing__signup_teaser-banner {
  background-image: url('/assets/ayria/landing/banner.png');
  background-repeat: no-repeat;
  height: 540px;
  background-position: bottom;
  background-size: cover;
  position: relative;
}

.landing__signup_teaser-banner-footer {
  background-image: url('/assets/ayria/landing/banner-footer.png');
  background-repeat: no-repeat;
  height: 220px;
  background-size: 100%;
  padding: 12px;
  color: #f4f5f8;
  a {
    color: #222428;
  }
}

.landing_plans-main-text-container {
  padding: 0 19px;
  text-align: center;
  p {
    padding: 12px;
  }
}

.landing_plans-banner {
  background-image: url('/assets/ayria/landing/plans-banner.png');
  background-repeat: no-repeat;
  height: 330px;
  background-size: cover;
  background-position: center;
}

#plans-info,
#join {
  position: relative;
  top: -80px;
}

#join {
  margin-bottom: -80px;
}

.landing_plans-banner-footer {
  background-color: #46c0bd;
  padding: 20px;
  text-align: justify;
}

.landing__join-login-and-signup {
  display: flex;
}

.landing__join-plans {
  padding: 20px 0px;
}

.landing__join-login-and-signup {
  .login-button {
    background-image: url('/assets/ayria/landing/login.png');
    background-repeat: no-repeat;
    height: 160px;
    background-size: 100%;
    background-position: right;
    width: 300px;
    text-align: center;
    color: #ffffff;
    vertical-align: middle;
    font-size: 16px;
    line-height: 9.3rem;
  }

  .signup-button {
    background-image: url('/assets/ayria/landing/signup.png');
    background-repeat: no-repeat;
    height: 160px;
    background-size: 100%;
    background-position: right;
    width: 300px;
    text-align: center;
    color: #ffffff;
    vertical-align: middle;
    font-size: 16px;
    line-height: 9.3rem;
  }
}

.landing__join-plans {
  .star-plan {
    cursor: pointer;
    width: 50vw;
  }

  .galaxy-plan {
    cursor: pointer;
    width: 50vw;
  }
}

.landing__join-footer-message {
  font-size: 18px;
  padding: 12px;
  text-align: center;
}

.landing__join-footer {
  background-image: url('/assets/ayria/landing/footer-end.png');
  background-repeat: no-repeat;
  height: 120px;
  width: 100%;
  background-size: 100%;
  text-align: center;
  line-height: 10.5rem;
  background-position: bottom;
  color: #f4f5f8;
  font-size: 20px;
}

.karafarin-theme {
  .landing__special_offer {
    font-size: 30px;
    color: var(--ion-color-secondary);
  }
  .landing__header_menu button {
    color: var(--ion-color-secondary);
  }
  .landing__join-footer {
    color: var(--ion-color-karafarin-contrast);
  }
}
