
.purchase-form {
  display: inline;
  padding: 1em;
}

.card-fields {
  text-align: left;
  direction: ltr;
}

.table td {
  border-top: none;
}

.float-right {
  float: right;
}