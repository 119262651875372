
.bg-gold {
  background-image: url("../../../assets/images/bg/Gold_02.jpg") !important;
}

.bg-star {
  background-image: url("../../../assets/images/bg/Star_02.png") !important;
}

.bg-galaxy {
  background-image: url("../../../assets/images/bg/Galaxy_02.jpg") !important;
}

.price-table::before {
  background: inherit;
  visibility: hidden;
}

.price-title {
  padding-top: 150px;
}

section {
  padding-top: 1em;
}

.section-title {
  margin-bottom: 1em;
}
.text-green {
    color: #264013
}

.text-red {
    color: #a52f2f;
}
