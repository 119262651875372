.btn:hover,
.btn:focus,
.btn:active {
  outline: 0 !important;
}
/* entire container, keeps perspective */
.card-container {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  margin-bottom: 30px;
}
/* flip the pane when hovered */
.card-container:not(.manual-flip):hover .card,
.card-container.hover.manual-flip .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card-container.static:hover .card,
.card-container.static.hover .card {
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
}
/* flip speed goes here */
.card {
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
}

/* front pane, placed above back */
.front {
  z-index: 2;
}

/* back, initially hidden pane */
.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 3;
}

.back .btn-simple {
  position: absolute;
  left: 0;
  bottom: 4px;
}
/*        Style       */

.card {
  background: none repeat scroll 0 0 #ffffff;
  border-radius: 4px;
  color: #444444;
}
.card-container,
.front,
.back {
  width: 100%;
  height: 250px;
  border-radius: 4px;
}
.card .cover {
  height: 105px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}
.card .cover img {
  width: 100%;
}
.card .user {
  border-radius: 50%;
  display: block;
  height: 120px;
  margin: -55px auto 0;
  overflow: hidden;
  width: 120px;
}
.card .user img {
  background: none repeat scroll 0 0 #ffffff;
  border: 4px solid #ffffff;
  width: 100%;
}

.card .content {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  padding: 10px 20px 20px;
}
.card .content .main {
  min-height: 160px;
}
.card .back .content .main {
  height: 215px;
}
.card .name {
  font-size: 22px;
  line-height: 28px;
  margin: 10px 0 0;
  text-align: center;
  text-transform: capitalize;
}
.card h5 {
  margin: 5px 0;
  font-weight: 400;
  line-height: 20px;
}
.card .profession {
  color: #999999;
  text-align: center;
  margin-bottom: 20px;
}
.card .footer {
  border-top: 1px solid #eeeeee;
  color: #999999;
  margin: 30px 0 0;
  padding: 10px 0 0;
  text-align: center;
}
.card .footer .social-links {
  font-size: 18px;
}
.card .footer .social-links a {
  margin: 0 7px;
}
.card .footer .btn-simple {
  margin-top: -6px;
}
.card .header {
  padding: 15px 20px;
  height: 90px;
}
.card .motto {
  border-bottom: 1px solid #eeeeee;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center;
}

.card .stats-container {
  width: 100%;
  margin-top: 50px;
}
.card .stats {
  display: block;
  float: left;
  width: 33.333333%;
  text-align: center;
}

.card .stats:first-child {
  border-right: 1px solid #eeeeee;
}
.card .stats:last-child {
  border-left: 1px solid #eeeeee;
}
.card .stats h4 {
  font-weight: 300;
  margin-bottom: 5px;
}
.card .stats p {
  color: #777777;
}
/*      Just for presentation        */

.title {
  color: #506a85;
  text-align: center;
  font-weight: 300;
  font-size: 44px;
  margin-bottom: 90px;
  line-height: 90%;
}
.title small {
  font-size: 17px;
  color: #999;
  text-transform: uppercase;
  margin: 0;
}
.space-30 {
  height: 30px;
  display: block;
}
.space-50 {
  height: 50px;
  display: block;
}
.space-200 {
  height: 200px;
  display: block;
}
.white-board {
  background-color: #ffffff;
  min-height: 200px;
  padding: 60px 60px 20px;
}
.ct-heart {
  color: #f74933;
}

pre.prettyprint {
  background-color: #ffffff;
  border: 1px solid #999;
  margin-top: 20px;
  padding: 20px;
  text-align: left;
}
.atv,
.str {
  color: #05ae0e;
}
.tag,
.pln,
.kwd {
  color: #3472f7;
}
.atn {
  color: #2c93ff;
}
.pln {
  color: #333;
}
.com {
  color: #999;
}

.btn-simple {
  opacity: 0.8;
  color: #666666;
  background-color: transparent;
}

.btn-simple:hover,
.btn-simple:focus {
  background-color: transparent;
  box-shadow: none;
  opacity: 1;
}
.btn-simple i {
  font-size: 16px;
}

.navbar-brand-logo {
  padding: 0;
}
.navbar-brand-logo .logo {
  border: 1px solid #333333;
  border-radius: 50%;
  float: left;
  overflow: hidden;
  width: 60px;
}
.navbar .navbar-brand-logo .brand {
  color: #ffffff;
  float: left;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 10px;
  margin-top: 10px;
  width: 60px;
}
.navbar-default .navbar-brand-logo .brand {
  color: #555;
}

/*       Fix bug for IE      */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .front,
  .back {
    -ms-backface-visibility: visible;
    backface-visibility: visible;
  }

  .back {
    visibility: hidden;
    -ms-transition: all 0.2s cubic-bezier(0.92, 0.01, 0.83, 0.67);
  }
  .front {
    z-index: 4;
  }
  .card-container:not(.manual-flip):hover .back,
  .card-container.manual-flip.hover .back {
    z-index: 5;
    visibility: visible;
  }
}
